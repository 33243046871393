import Loader from 'src/components/Loader/Loader.component';
import moment from 'moment';
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useLanguage } from 'src/services/i18n/i18n.context';
import routerConfig from 'src/routers/routers.container';
import './App.css';

const router = createBrowserRouter(routerConfig);

const App: React.FC = () => {
  const { language, isLoadingLang, isLoadingUpLang, isLangLabelsLoaded } =
    useLanguage();
  useEffect(() => {
    moment.locale(language.substring(0, 2));
  }, [language]);

  if (isLoadingLang || isLoadingUpLang || !isLangLabelsLoaded) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }

  return <RouterProvider router={router} />;
};

export default App;
