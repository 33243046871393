import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as eyeCloseIconSrc } from 'src/images/icons/eye_close.svg';
import { ReactComponent as eyeOpenIconSrc } from 'src/images/icons/eye_open.svg';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { passwordField } from 'src/services/registration/registration.config';
import colors from 'src/style-utils/colors';
import { FormGroup, Input, LabelInput } from 'src/style/styleInput.style';
import toggle from 'src/utils/functions/toggle';
import useGetComponentRenderUniqueId from 'src/utils/hooks/useGetComponentRenderUniqueId';
import { passwordRange } from 'src/utils/validation/password';
import { useCheckValue } from './InputPasswordField.hooks';
import PasswordStatus from './partials/PasswordStatus.component';
import { EyeIconWrapper } from './style/InputPassword.style';

export type InputPasswordFieldProps = {
  label?: string;
  name?: string;
  id?: string;
  hidden?: boolean;
  isPresentMessage?: boolean;
  handleChangeUsingSetValue?: boolean;
  autoComplete?: string;
  inputComponent?: React.ComponentType<any>;
  formGroupChildren?: React.ReactNode;
  className?: string;
  spellCheck?: boolean;
  analyticsName?: string;
  required?: boolean;
  autoCorrect?: 'off' | 'on';
};

const InputPasswordField = React.memo(function InputPasswordField({
  label,
  isPresentMessage,
  id,
  hidden,
  spellCheck = false,
  autoCorrect = 'off',
  analyticsName = 'password',
  autoComplete = 'new-password',
  inputComponent: InputComponent = Input,
  name = passwordField,
  ...otherProps
}: InputPasswordFieldProps) {
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  const handleChagePasswordVisiblityClick = React.useCallback(() => {
    setIsPasswordVisible(toggle);
  }, []);

  const {
    control,
    formState: { errors, touchedFields },
    getValues,
  } = useFormContext();
  const {
    field: { ...rest },
  } = useController({
    name,
    control,
  });
  const { required } = otherProps;
  const fieldValue: string = getValues(name);
  const compId = useGetComponentRenderUniqueId('counter');
  const domId = id || compId;
  const {
    hasNumber,
    hasUpperCase,
    hasLowerCase,
    hasValidLength,
    hasSpecialChar,
  } = useCheckValue(fieldValue);
  const activated = fieldValue.length > 0;
  const error = errors && errors[name];
  const touched = touchedFields[name];
  const isError = typeof error === 'string' && activated && touched;

  return (
    <div
      data-component="input-password-field"
      hidden={hidden}
      style={{ margin: '0.8rem 0' }}
    >
      {label && (
        <LabelInput htmlFor={domId}>
          <Translate id={label} />
        </LabelInput>
      )}
      <FormGroup isError={isError}>
        <InputComponent
          type={isPasswordVisible ? 'text' : 'password'}
          required={required}
          id={domId}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          autoComplete={autoComplete}
          maxLength={passwordRange.max}
          {...rest}
        />
        <EyeIconWrapper
          onClick={handleChagePasswordVisiblityClick}
          hasErrorIcon={false}
          aria-label={isPasswordVisible ? 'hide password' : 'show password'}
          iconColor={
            activated ? colors.textColorBlack : colors.textColorLightGrey
          }
        >
          <Icon
            iconSize="1.8rem"
            iconHeight="1.8rem"
            svgIconComponent={
              isPasswordVisible ? eyeCloseIconSrc : eyeOpenIconSrc
            }
          />
        </EyeIconWrapper>
      </FormGroup>
      <PasswordStatus
        activated={activated}
        hasValidLength={hasValidLength}
        hasNumber={hasNumber}
        hasUpperCase={hasUpperCase}
        hasLowerCase={hasLowerCase}
        hasSpecialChar={hasSpecialChar}
      />
    </div>
  );
});

export default InputPasswordField;
