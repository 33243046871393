import InstallmentCard from 'src/components/InstallmentCard/InstallmentCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { Installment } from 'src/services/installments/installments.types';
import { P, PSmall } from 'src/style-utils/typographic';
import { MaxWidthContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { InstallmentsTabsProps } from '../TabsInstallments.types';
import InstallmentsModalLodable from '../modal/InstallmentsModal.lodable';
import colors from 'src/style-utils/colors';
import PendingBalanceInfoModalLoadable from 'src/components/PendingBalanceInfoModal/PendingBalanceInfoModal.loadable';
import { statusCard } from '../../../../orderDetail/tabs/TabsOrder.types';
import { NoTabsData } from '../NoTabsData.component';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';

export const InstallmentsProcessing: React.FC<InstallmentsTabsProps> = ({
  installments,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [dataInstallment, setDataInstallment] = useState<
    Installment | undefined
  >();
  const [modalOpen, setModalOpen] = useState(false);

  const onClickHandler = (installment: Installment) => {
    setDataInstallment(installment);
    setShowModal(true);
  };
  const ctaHandler = () => {
    navigate(
      `${RoutePath.orderDetail}${dataInstallment?.OrderGuid}/${RoutePath.info}`,
    );
  };
  return (
    <MaxWidthContainer maxWidth="66.8rem" noXMargin marginBottom="3.2rem">
      <Flex flexDirection="column" gap="1.6rem">
        <AlertComponent
          variant="informative"
          descriptionLbl="lbl.PBModalBodyInstalmentsBuyer"
          hyperlinkLbl="lbl.whatsthemeaning"
          onClick={() => setModalOpen(true)}
        />
        {modalOpen && (
          <PendingBalanceInfoModalLoadable
            modalTitle="lbl.PBModalTitle"
            modalBody="lbl.PBModalBodyBuyer"
            i18n
            handleClose={() => setModalOpen(false)}
          />
        )}
        {installments?.ResultSet.InstallmentsProcessing.map(
          (installment, i) => {
            return (
              <Flex gap="1.6rem" flexDirection="column" key={i}>
                {i > 0 && (
                  <hr
                    className="my-8 border-pgrey lg:my-12"
                    style={{ color: colors.textColorGrey }}
                  ></hr>
                )}
                <Flex justify="space-between">
                  <PSmall className="capitalize">
                    <Translate
                      id="lbl.titleProcessingInst"
                      data={{
                        OrderId: installment.OrderId,
                        date: refactorFormatDate(
                          installment.Expiration,
                          ' DD MMMM YYYY',
                        ),
                      }}
                    />
                  </PSmall>
                </Flex>
                <InstallmentCard
                  key={installment.Guid}
                  name={installment.MerchantName}
                  date={installment.Expiration}
                  amount={installment.Amount}
                  NumRata={installment.INo}
                  status={statusCard[installment.Status]}
                  orderStatus={installment.OrderStatus}
                  onClickHandler={() => onClickHandler(installment)}
                  shadows
                />
              </Flex>
            );
          },
        )}
        {installments?.ResultSet.InstallmentsProcessingCount === 0 && (
          <NoTabsData
            title="lbl.noProcessingTitle"
            subtitle="lbl.noProcessingSubtitle"
          />
        )}
        {showModal && (
          <InstallmentsModalLodable
            buttonTitle={'lbl.goToOrder'}
            ctaHandler={ctaHandler}
            modalTitle="text.installmentDetail"
            i18n
            data={dataInstallment}
            handleClose={() => setShowModal(false)}
          />
        )}
      </Flex>
      {installments &&
        installments?.ResultSet.InstallmentsProcessingCount > 0 && (
          <div className="mb-10 mt-8 border-t border-pgreyoutlined pt-4 text-center">
            <P>
              <Translate id="text.nothingElseToShow" />
            </P>
          </div>
        )}
    </MaxWidthContainer>
  );
};
