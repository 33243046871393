import React from 'react';
import { authTokenKey } from './session.config';
import { SessionReducerInit, SessionReducerShape } from './session.types';
import { useProfileStore } from 'src/store/store';

const defaultSessionContext: SessionReducerInit = {
  info: {},
  auth: {
    authToken: null,
    from: '',
    verifiedAt: null,
    requestId: '',
  },
};

const SessionContext = React.createContext<SessionReducerShape>(
  defaultSessionContext as SessionReducerShape,
);

export const SessionProvider: React.FC<SessionReducerShape> = ({
  children,
  info,
  auth,
}) => {
  const [sessionInfo, setSessionInfo] = React.useState<SessionReducerInit>({
    info,
    auth,
  });
  const setValue = useProfileStore((state) => state.setValue);
  const handleLogout = () => {
    //Set localStorage key token

    // window.localStorage.removeItem(authTokenKey);
    // window.localStorage.removeItem(typeAccountKey);
    window.localStorage.clear();
    setValue('AccountType', '');
    setValue('AccountStatus', '');
    document.cookie =
      'language=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setSessionInfo({
      info,
      auth: { ...auth, authToken: null, verifiedAt: null },
    });
  };
  const handleLogin = (authToken: string) => {
    //Set localStorage key token
    window.localStorage.setItem(authTokenKey, authToken);

    setSessionInfo({
      info,
      auth: { ...auth, authToken: authToken },
    });
  };
  return (
    <SessionContext.Provider
      value={{
        ...defaultSessionContext,
        ...sessionInfo,
        onLogin: handleLogin,
        onLogout: handleLogout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export function useSession() {
  const context = React.useContext(SessionContext);

  return context;
}
