import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Logo } from 'src/images/icons/logo-opyn.svg';
import './Footer.style.css';
import { AlertComponent } from '../ORION/Alert/Alert.component';

const footerLinks = [
  {
    title: 'footer.opynWebsite',
    url: 'https://www.opyn.eu',
  },
  {
    title: 'footer.privacyPolicy',
    url: 'https://www.opyn.eu/privacy-policy',
  },
  {
    title: 'lbl.cookiesPolicy',
    url: 'https://www.opyn.eu/cookie-policy',
  },
];

interface FooterProps {
  onlyDesktop?: boolean;
}

const Footer: React.FC<FooterProps> = ({ onlyDesktop }: FooterProps) => {
  const year = new Date().getFullYear();
  const showCookieBanner = () => {
    Cookiebot?.show();
    window.addEventListener(
      'CookiebotOnAccept',
      function (e) {
        window.location.reload();
      },
      false,
    );
  };

  const getEnvironment = (): string => {
    const host = window.location.host;
    if (host.includes('.dev.') || host.includes('localhost')) {
      return 'dev';
    }
    if (host.includes('.test.')) {
      return 'test';
    }
    return 'production';
  };

  const isWithinDateRange = (startDate: Date, endDate: Date) => {
    const today = new Date();
    return today >= startDate && today <= endDate;
  };

  const ENV = getEnvironment();
  const alertStartDate = new Date(2024, 7, 12);
  const alertEndDate = new Date(2024, 7, 16);

  const getQueryParam = (param: string): string | null => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param);
  };

  const testAlert = getQueryParam('testAlert') === 'true';

  const shouldShowAlert =
    (ENV !== 'production' && testAlert) ||
    isWithinDateRange(alertStartDate, alertEndDate);

  return (
    <>
      <footer
        data-component="footer"
        className={`${onlyDesktop ? 'onlyDesktop' : ''} border-t-2 border-pgreyoutlined py-8 text-xs leading-4 text-pgrey`}
      >
        <div className="container grid gap-4">
          <div className="grid items-center justify-between gap-4 lg:flex">
            <Logo width="64" height="16" className="h-4 w-16" />
            <ul className="flex flex-wrap">
              {footerLinks.map(({ title, url }) => (
                <li key={title}>
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer underline"
                  >
                    <Translate id={title} />
                  </a>
                  &nbsp;&middot;&nbsp;
                </li>
              ))}
              <li>
                <button
                  onClick={showCookieBanner}
                  className="cursor-pointer underline"
                >
                  <Translate id="footer.cookieManagement" />
                </button>
                &nbsp;&middot;&nbsp;
              </li>
              <p>&copy; {year} OPYN</p>
            </ul>
          </div>
          {shouldShowAlert && (
            <AlertComponent variant="default" descriptionLbl="lbl.timedAlert" />
          )}
          <hr />
          <div>
            <p>
              <Translate id="text.footerEcommerceCheckout" />
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
