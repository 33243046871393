import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { getStripeLink } from './stripe.config';

export const getStripeLinksMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: getStripeLink,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
