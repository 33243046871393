import Translate from 'src/components/Translate/Translate.component';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { PSmall } from 'src/style-utils/typographic';
import {
  BalanceContainer,
  BalanceDisclaimerContainer,
  CustomCurrentBalance,
  CustomDisclaimer,
  CustomHyperlink,
  CustomPendingBalance,
  CustomTitle,
  OrderDatasContainer,
  PendingIncomeContainer,
  PendingIncomeContentContainer,
  PendingIncomeTitle,
  Section,
} from './CurrentBalance.style';
import { useState } from 'react';
import PendingBalanceInfoModalLoadable from '../PendingBalanceInfoModal/PendingBalanceInfoModal.loadable';

type Props = {
  currentBalance: number;
  lastTransferDate: string;
  stripeUrl: string;
  pendingBalance: number;
};

const CurrentBalance = ({
  currentBalance,
  lastTransferDate,
  stripeUrl,
  pendingBalance,
}: Props) => {
  const {
    formatters: { formatCurrency, formatDate },
  } = useI18n();
  const [modalOpen, setModalOpen] = useState(false);

  const lastTransfer = formatDate(new Date(lastTransferDate));
  const noTransferDate = '01/01/1';
  return (
    <>
      <Section data-component="current-balance">
        <OrderDatasContainer>
          <BalanceContainer>
            <CustomTitle>
              <Translate id="text.currentBalanceIs" />
            </CustomTitle>
            <CustomCurrentBalance>
              {formatCurrency(currentBalance, { removeWhiteSpaces: true })}
            </CustomCurrentBalance>
          </BalanceContainer>

          <BalanceDisclaimerContainer>
            <CustomDisclaimer>
              <Translate id="text.automaticTransferMonday" /> {''}
              <a href={stripeUrl} target="_blank" rel="noreferrer">
                <u>
                  <Translate id="link.goToStripeAlternate" />
                </u>
              </a>
            </CustomDisclaimer>

            {lastTransfer !== noTransferDate && (
              <div className="w-fit rounded-md bg-bgray px-2 py-1">
                <PSmall>
                  <Translate id="text.lastWithdrawal" />
                  <strong>{lastTransfer}</strong>
                </PSmall>
              </div>
            )}
          </BalanceDisclaimerContainer>
        </OrderDatasContainer>

        {pendingBalance > 0 && (
          <PendingIncomeContainer>
            <PendingIncomeContentContainer>
              <PendingIncomeTitle>
                <Translate id="lbl.pendingIncomesTitle" />
              </PendingIncomeTitle>
              <CustomPendingBalance>
                {formatCurrency(pendingBalance, { removeWhiteSpaces: true })}
              </CustomPendingBalance>
              <CustomHyperlink onClick={() => setModalOpen(true)}>
                <u>
                  <Translate id="lbl.whatsthemeaning" />
                </u>
              </CustomHyperlink>
            </PendingIncomeContentContainer>
          </PendingIncomeContainer>
        )}
      </Section>
      {modalOpen && (
        <PendingBalanceInfoModalLoadable
          modalTitle="lbl.PBModalTitle"
          modalBody="lbl.PBModalBodyMerchant"
          i18n
          handleClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default CurrentBalance;
