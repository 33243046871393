import styled, { css } from 'styled-components';
import { TableTheme } from '../DataTable.types';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { Flex } from 'src/style/flexbox.style';

export type CellProps = {
  w?: string;
  minWMobile?: string;
  minWDesktop?: string;
  maxWMobile?: string;
  maxWDesktop?: string;
};

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;
Body.displayName = 'DataTableBody';

export const Head = styled.div`
  display: flex;
  width: 100%;
  padding: 0.8rem 1.3rem;
  ${({ theme }: { theme: TableTheme }) => theme.header?.style}
`;
Head.displayName = 'Head';

export const HeadRow = styled.div`
  align-items: stretch;
  width: 100%;
  margin-bottom: 0.751rem;
  justify-content: space-between;
  color: ${colors.textHeader};
  display: none;
  font-size: 1.2rem;
  line-height: 1.8rem;
  ${media.tablet`
  display: flex;
  `};
`;
HeadRow.displayName = 'HeadRow';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${({ theme }: { theme: TableTheme }) => theme.tableWrapper?.style}
`;
Wrapper.displayName = 'Wrapper';

export const Table = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
`;
Table.displayName = 'Table';

export type TableRowWrapperProps = {
  stripes?: 'odd' | 'even' | null;
  borderColor?: string;
};

export const TableRowWrapper = styled.div<TableRowWrapperProps>`
  background: ${colors.white};
  box-shadow: 0px 1px 4px 1px rgba(53, 48, 47, 0.24);
  border-radius: 1.2rem;
  padding: 0.8rem 1.2rem;
  margin-bottom: 1.6rem;
  border: 0.1rem solid ${colors.greyBorder};
  :has([data-info='danger']) {
    border-color: ${colors.redStatus};
  }
  :has([data-info='warning']) {
    border-color: ${colors.yellowStatus};
  }
  ${({ theme }: { theme: TableTheme }) => theme.rowsCellWrapper?.style};
`;
TableRowWrapper.displayName = 'TableRowWrapper';

const commonCellStyles = css<CellProps>`
  border-radius: 0.4rem;
  margin-bottom: 0.8rem;
  width: ${({ w }) => w};
  max-width: ${({ maxWMobile }) => maxWMobile};
  min-width: ${({ minWMobile }) => minWMobile};
  padding: 0.7rem 0.8rem;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 2.2rem;
  ${({ maxWDesktop, minWDesktop }) => media.tablet`
    padding: 0.7rem 0;
    max-width:  ${maxWDesktop};
    min-width:  ${minWDesktop};
  `}
`;

export const CellRowHeader = styled(Flex)<CellProps>`
  background: ${colors.transparent};
  ${commonCellStyles}
  ${({ theme }: { theme: TableTheme }) => theme.headCells?.style}
`;

CellRowHeader.defaultProps = {
  w: '100%',
  maxWMobile: '100%',
};

CellRowHeader.displayName = 'Cell';

export const CellRow = styled(Flex)<CellProps>`
  ${commonCellStyles}
  background: #f5faff;
  & p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &::before {
    color: ${colors.textTableHeaderMobile};
    display: block;
    font-size: 1.2rem;
    content: attr(data-parent);
  }
  ${media.tablet`
    background: ${colors.transparent};
    border-radius: 0;
    margin-bottom: 0;
    padding : 0;
    justify-content: flex-start;

    &::before {
    content: '';
  }
  `};
  ${({ theme }: { theme: TableTheme }) => theme.cells?.style};
`;

CellRow.defaultProps = {
  w: '100%',
  maxWMobile: '100%',
};

CellRow.displayName = 'Cell';

export const TableRowCellWrapper = styled.div.attrs((props) => ({
  style: props.style,
}))<{
  striped?: boolean;
}>`
  min-height: 4.4rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  ${media.tablet`
  flex-direction: row;
`};
`;
TableRowCellWrapper.displayName = 'TableRowCellWrapper';

export default TableRowCellWrapper;
