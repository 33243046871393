import colors from 'src/style-utils/colors';
import { fadeIn } from 'src/style/keyframes';
import styled, { keyframes } from 'styled-components';
import { LoaderProps } from '../Loader.types';
import media from 'src/style-utils/media-queries';

export const LoaderContainer = styled.div<
  Pick<LoaderProps, 'viewMode' | 'active'>
>`
  position: relative;

  ${function manageViewMode({ viewMode = 'inline' }): string {
    switch (viewMode) {
      case 'fluid':
        return `
          display: block;
          width: 100%;
        `;
      case 'fullSize':
        return `
            display: block;
            width: 100%;
            height: 100%;
        `;
      case 'absolute':
        return `
          display: block;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          z-index: 2;
          pointer-events: none;
        `;
      case 'inline':
        return `
          display: inline-block;
        `;
    }
  }};
`;

export const OverlayContainer = styled.div<
  Pick<
    LoaderProps,
    | 'overlayViewMode'
    | 'active'
    | 'animationDelayMs'
    | 'backgroundColor'
    | 'backdropFilter'
  >
>`
  position: ${({ overlayViewMode }) =>
    overlayViewMode === 'fullscreen' ? 'fixed' : 'absolute'};
  width: 100%;
  height: 100%;
  background: ${({ backgroundColor }) => backgroundColor ?? colors.loaderBg};
  backdrop-filter: ${({ backdropFilter }) => backdropFilter ?? ''};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: ${({ overlayViewMode }) =>
    overlayViewMode === 'fullscreen' ? '999' : '10'};
  animation: ${fadeIn} 200ms linear;
  animation-fill-mode: both;
  animation-direction: ${({ active }) => (active ? 'normal' : 'reverse')};
  animation-delay: ${({ animationDelayMs = 0 }) => `${animationDelayMs}ms`};
`;

const cAnim = keyframes`
    50% {
        cy: 30;
        cx: 30;
    }
`;

const rotate = keyframes`
    50% {
        transform: rotate(180deg)
    }
    100% {
        transform: rotate(360deg)
    }
`;

export const OverlayIcon = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.2rem;
  height: 100%;
  padding: 2.4rem;
  inset: 0;
  margin: auto;

  > svg {
    animation: ${rotate} 2s cubic-bezier(0.4, 0.1, 0.4, 0.9) infinite;

    > * {
      animation: ${cAnim} 2s cubic-bezier(0.4, 0.1, 0.4, 0.9) infinite;
    }
  }
`;

export const CreditWorthinessBodyContainer = styled.div`
  display: flex;
  width: 327px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  ${media.tablet`
  display: flex;
  width: 560px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  `}
`;

export const CreditWorthinessBodyTitle = styled.h2`
  color: #35302f;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;

  ${media.tablet`
    font-size: 32px;
    line-height: 48px;
  `}
  .titleColored {
    color: ${colors.primaryGreen};
  }
`;

export const CreditWorthinessBodySubTitle = styled.span`
  color: #636e82;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  ${media.tablet`
    font-size: 24px;
    line-height: 36px;
  `}
`;
