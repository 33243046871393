import { ModalBody } from 'src/components/Modal/style/Modal.styles';
import Stepper from 'src/components/Stepper/Stepper.component';
import StepperRemodulation from 'src/components/Stepper/StepperRemodulation.component';
import { usePayByLinkStore } from 'src/store/store';

const StepResumeModalBody = () => {
  const { customInstallments, installmentsPaid, remodulation } =
    usePayByLinkStore((state) => state);

  return (
    <ModalBody>
      {remodulation ? (
        <StepperRemodulation
          dataStepper={installmentsPaid!.concat(
            customInstallments?.Installments!,
          )}
        />
      ) : (
        <Stepper dataStepper={customInstallments?.Installments!} />
      )}
    </ModalBody>
  );
};

export default StepResumeModalBody;
