import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import {
  PaySuccessCard,
  PSCardProps,
} from 'src/components/PaySuccessCard/PaySuccessCard.component';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Calendar } from 'src/images/icons/calendarSwipe.svg';
import { ReactComponent as CreditCardCircle } from 'src/images/icons/credit-card.svg';
import { ReactComponent as CreditCard } from 'src/images/icons/credit_card.svg';
import { ReactComponent as IconBill } from 'src/images/icons/iconBill.svg';
import { ReactComponent as RoundStepper } from 'src/images/icons/jump-stepper-round.svg';
import { ReactComponent as Phone } from 'src/images/icons/phone.svg';
import { ReactComponent as StepJumper } from 'src/images/icons/step-jumper.svg';
import { ReactComponent as OkPaymentSvg } from 'src/images/pageStatus/already-paid.svg';
import { ReactComponent as PaymentSvg } from 'src/images/pageStatus/payment-success.svg';
import { ReactComponent as EuroSymbol } from 'src/images/icons/euroSymbol.svg';
import HowDoesItWorkCard from 'src/pages/PurchaseFlow/subcomponents/HowDoesItWorkCard';
import SepaPaymentInfoModalLoadable from './partials/SepaPaymentInfoModal/SepaPaymentInfoModal.loadable';
import { PaymentFlowContextType } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { H4, H5, P } from 'src/style-utils/typographic';
import { getCookie } from 'src/utils/functions/cookie';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { getPaymentIcon } from 'src/components/PaymentMethodElement/PaymentMethodElement.helpers';
import { Flex } from 'src/style/flexbox.style';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';
import { AlertContainer } from 'src/components/ORION/Alert/Alert.style';
import {
  BodyContainer,
  ContentBodyContainer,
  CopyContainer,
  IconContainer,
  OrderDetailCardContainer,
  OrderDetailTitleContainer,
} from 'src/pages/registration/partials/SuccessPage/SuccessPage.style';

function PaymentSuccessPage() {
  const [showPopUp, setShowPopUp] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    formattedAmount,
    changeMethodHandler,
    successData,
    ChangedMethod,
    selectedMethodId,
  } = useOutletContext<PaymentFlowContextType>();
  const setValue = useCurrentTransactionStore((state) => state.setTransaction);
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const location = useLocation();
  const guidParam = location?.state?.guidParam;
  const navigate = useNavigate();
  const {
    formatters: { formatCurrency },
  } = useI18n();

  useEffect(() => {
    if (
      (ChangedMethod !== undefined &&
        ChangedMethod.Id !== successData?.PaymentMethod.Id) ||
      selectedMethodId === 0 ||
      successData?.PaymentMethod.Id !== selectedMethodId
    ) {
      setValue('showPaymentSetupPage', false);
      setTimeout(() => {
        setShowPopUp(true);
      }, 2000);
    }
  }, [successData]);

  const {
    InstallmentUnpaidCount,
    CurrentInstallmentsPaidCount,
    LastPaidINo,
    MerchantName,
    NextAmount,
    NextDueDate,
    PaymentMethod,
    RemainingAmount,
    IsSingleInstallment,
  } = successData ?? {};
  const isSepaDebit = PaymentMethod?.Type === 'sepa_debit';

  const userGuest =
    currentOrder?.BuyerRegistrationStatus &&
    currentOrder?.BuyerRegistrationStatus !== 'registered';
  const lastInstallment = RemainingAmount === 0;
  const labels = {
    title: lastInstallment
      ? 'text.paidLastInstallment'
      : 'text.paidInstallmentNumberX',
    subtitle: lastInstallment
      ? 'text.paymentSuccessSubtitleLast'
      : 'text.paymentSuccessSubtitle',
  };
  const MainIcon = IsSingleInstallment ? OkPaymentSvg : PaymentSvg;
  const buttonLabel = (() => {
    if (guidParam) return 'lbl.completeProfile';
    if (userGuest) return 'lbl.completeSignUp';
    return 'lbl.enterReservedArea';
  })();
  const changed = getCookie('changed');
  const icon = getPaymentIcon(PaymentMethod?.Details.Network);
  const { Last4, Country } = PaymentMethod?.Details ?? {};

  if (CurrentInstallmentsPaidCount === 0) {
    return (
      <Navigate to={RoutePath.ThankyouPageError + RoutePath.paymentFailed} />
    );
  }

  const cardValues: PSCardProps[] = [
    {
      icon: EuroSymbol,
      lbl: 'text.paymentMethod',
      value: (
        <Flex
          style={{
            alignItems: 'center',
          }}
          gap="1.6rem"
        >
          <img className="h-10 w-10 p-1" src={icon} alt="card logo" />
          <H4 textAlign="right">
            {PaymentMethod?.Type === 'sepa_debit' && Country + ' '}
            &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;
            {Last4}
          </H4>
        </Flex>
      ),
      variant: 'column',
    },
    {
      icon: Calendar,
      lbl: 'text.nextRata',
      value: formatCurrency(NextAmount ? NextAmount : 0),
      note: NextDueDate
        ? refactorFormatDate(NextDueDate, 'ddd. DD MMMM')
        : undefined,
    },
    {
      icon: StepJumper,
      lbl: 'text.residualRataCount',
      value: InstallmentUnpaidCount ?? 0,
    },
    {
      icon: IconBill,
      lbl: 'lbl.remainingAmount',
      value: formatCurrency(RemainingAmount ?? 0),
    },
  ];

  const SingleInstallmentCardValues: PSCardProps[] = [
    {
      icon: EuroSymbol,
      lbl: 'text.paymentMethod',
      value: (
        <Flex
          style={{
            alignItems: 'center',
          }}
          gap="1.6rem"
        >
          <img className="h-10 w-10 p-1" src={icon} alt="card logo" />
          <H4 textAlign="right">
            {PaymentMethod?.Type === 'sepa_debit' && Country + ' '}
            &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;
            {Last4}
          </H4>
        </Flex>
      ),
      variant: 'column',
    },
  ];

  return (
    <>
      {changed === 'true' && (
        <Toast
          variant="success"
          title="text.changeMethodAlertTitle"
          subTitle="text.youAreChangingPaymentSubtitle"
          timeout={5000}
        />
      )}

      {isSepaDebit && (
        <>
          <AlertContainer>
            <AlertComponent
              variant="default"
              titleLbl="lbl.defAlertTYpage"
              hyperlinkLbl="lbl.whatsthemeaning2"
              onClick={() => setModalOpen(true)}
            />
          </AlertContainer>
          {modalOpen && (
            <SepaPaymentInfoModalLoadable
              modalTitle="lbl.sepaModalTItle"
              modalBody="lbl.sepaModalBody"
              i18n
              handleClose={() => setModalOpen(false)}
            />
          )}
        </>
      )}

      {IsSingleInstallment ? (
        <>
          <BodyContainer>
            <IconContainer className="relative flex items-center justify-between">
              <MainIcon className="mx-auto w-66 lg:w-96" />
            </IconContainer>
            <ContentBodyContainer>
              <CopyContainer>
                <H4>
                  <Translate
                    id="text.paidSingleInstallment"
                    data={{
                      amount: formattedAmount,
                      merchant: MerchantName,
                    }}
                  />
                </H4>
                <span style={{ color: '#636E82' }}>
                  <Translate id="text.visitReservedArea" />
                </span>
              </CopyContainer>
            </ContentBodyContainer>
            <ContentBodyContainer>
              {SingleInstallmentCardValues.map((item, i) => (
                <PaySuccessCard key={i} {...item} />
              ))}
            </ContentBodyContainer>
          </BodyContainer>
          <ButtonContainer marginTop="0.8rem">
            <Button
              onClick={() => {
                if (!userGuest)
                  return navigate(`${RoutePath.privateArea}?typeAccount=BUYER`);
                return navigate(
                  `${RoutePath.registrationGuest}${RoutePath.emailStep}?guid=${currentOrder?.BuyerGuid}`,
                );
              }}
              variant="Primary"
              type="submit"
              minWidth="100%"
              sizeOnDesktop="medium"
              i18n
              translatedText={buttonLabel}
            />
          </ButtonContainer>
        </>
      ) : (
        <>
          <div
            className="container grid gap-6 text-center"
            style={{ marginTop: '2.4rem', marginBottom: '4rem' }}
          >
            <div className="relative flex items-center justify-between">
              <MainIcon className="mx-auto w-66 lg:w-96" />
              <div className="absolute w-full text-center text-[9rem] font-bold text-pblack-400">
                {LastPaidINo}
              </div>
            </div>

            <ContentBodyContainer>
              <H4>
                <Translate
                  id={labels.title}
                  data={{
                    x: LastPaidINo,
                    merchant: MerchantName,
                  }}
                />
              </H4>
              {lastInstallment && (
                <P>
                  <Translate id="text.paymentSuccessSubtitleLast" />
                </P>
              )}
            </ContentBodyContainer>
            {lastInstallment && (
              <>
                <ContentBodyContainer>
                  {SingleInstallmentCardValues.map((item, i) => (
                    <PaySuccessCard key={i} {...item} />
                  ))}
                </ContentBodyContainer>
              </>
            )}

            {userGuest && (
              <>
                <H5 light textAlign="left" margin="4.8rem 0 1.6rem">
                  <Translate id="lbl.whyRegister" />
                </H5>
                <div className="mb-14 grid gap-4 text-left lg:grid-cols-2">
                  <HowDoesItWorkCard
                    title="text.keepTrack"
                    icon={RoundStepper}
                  />
                  <HowDoesItWorkCard
                    title="text.associatePayment"
                    icon={CreditCardCircle}
                  />
                  <HowDoesItWorkCard title="text.accessAnywhere" icon={Phone} />
                </div>
              </>
            )}

            <ButtonContainer marginTop="0.8rem">
              <Button
                onClick={() => {
                  if (!userGuest)
                    return navigate(
                      `${RoutePath.privateArea}?typeAccount=BUYER`,
                    );
                  return navigate(
                    `${RoutePath.registrationGuest}${RoutePath.emailStep}?guid=${currentOrder?.BuyerGuid}`,
                  );
                }}
                variant="Primary"
                type="submit"
                minWidth="100%"
                sizeOnDesktop="medium"
                i18n
                translatedText={buttonLabel}
              />
            </ButtonContainer>
          </div>

          {!lastInstallment && (
            <div className="bg-white py-10">
              <div className="container">
                <OrderDetailTitleContainer>
                  <H4 light>
                    <Translate id="lbl.TyPageDetailTitle" />
                  </H4>
                  <P>
                    <Translate id="text.paymentSuccessSubtitle" />
                  </P>
                </OrderDetailTitleContainer>
                <OrderDetailCardContainer>
                  {cardValues.map((item, i) => (
                    <PaySuccessCard key={i} {...item} />
                  ))}
                </OrderDetailCardContainer>
              </div>
            </div>
          )}
        </>
      )}

      {showPopUp && (
        <DoubleChoicePopUp
          handleSecondAction={() => {
            setValue('paymentMethodId', PaymentMethod?.Id ?? 0);
            changeMethodHandler({
              paymentMethodId: PaymentMethod?.Id ?? 0,
              stayInPage: true,
            });
          }}
          modalTitle="text.wouldChangePaymentMethod"
          handleClose={() => setShowPopUp(false)}
          firstButtonLabel="text.yesChangeMethod"
          lastButtonLabel="text.noChangeMethod"
          mainText="text.wouldChangePaymentMethodDescr"
          icon={CreditCard}
        />
      )}

      {window.innerWidth < 1024 && <div style={{ height: '15vh' }}></div>}
    </>
  );
}

export default PaymentSuccessPage;
