import { SyncFieldValidator } from './types';
import { validateFieldSync } from './validate-generators';

export const errorMessage = 'error.required';
export const errorMessageCustom = 'error.requiredCustom';

const required: SyncFieldValidator = function required(
  value: unknown,
): string | undefined {
  if (!value) {
    return errorMessage;
  }

  return undefined;
};

export const requiredCustomMessage: SyncFieldValidator = function required(
  value: unknown,
): string | undefined {
  if (!value) {
    return errorMessageCustom;
  }

  return undefined;
};

export const createRequiredSelect = function createRequiredSelect(
  defaultValue: unknown,
): SyncFieldValidator {
  return validateFieldSync(function requiredSelect(value) {
    if (value === defaultValue) {
      return errorMessage;
    }
    return undefined;
  });
};

export const requiredForMonetaryValues = function requiredForMonetaryValues(
  value: unknown,
): string | undefined {
  // We deem -1 as the default value for numbers.
  if ((!value && value !== 0) || value === -1) {
    return errorMessage;
  }

  return undefined;
};

export default required;
