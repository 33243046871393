import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { H5 } from 'src/style-utils/typographic';
import CheckoutForm from './partials/checkoutForm.component';
import { STRIPE_PROMISE } from 'src/settings';

export type StateStripe = {
  type?: string;
  amount?: string | number;
  guidParam?: string;
  paymentDate: string;
  orderGuid: string;
};
const PaymentForm: React.FC<{
  secretKey?: string;
  stateStripe: StateStripe;
}> = ({ secretKey, stateStripe }) => {
  const clientSecretStripe = secretKey;
  const [clientSecret] = React.useState(clientSecretStripe);

  const stripePromise = loadStripe(`${STRIPE_PROMISE}`);

  const { language } = useLanguage();

  const options: StripeElementsOptions = {
    clientSecret,
    locale: language,
  };

  return (
    <div>
      <H5 marginBottom="2.4rem">
        <Translate id="text.addData" />
      </H5>
      <Elements
        stripe={stripePromise}
        options={options}
        data-cookieconsent="ignore"
      >
        <CheckoutForm stateStripe={stateStripe} />
      </Elements>
    </div>
  );
};

export default PaymentForm;
