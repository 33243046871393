import { RoutePath } from 'src/routers/routers.config';
import { UsageSettingArrayType } from './usageSetting.types';

export const UsageSettingArray: UsageSettingArrayType = [
  {
    path: RoutePath.usageSettingOptions,
    text: 'text.linkPaymentWAdvPayment',
    divider: true,
  },
  {
    path: RoutePath.usageSettingSavedTemplates,
    text: 'lbl.savedTemplates',
    divider: false,
  },
];
