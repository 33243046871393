import ProgressBarComponent, {
  ProgressBarProps,
} from './ProgressBar/ProgressBar.component';
import React from 'react';
import colors from 'src/style-utils/colors';
import { H5, PSmall } from 'src/style-utils/typographic';
import { LinkDetailInfoContainer } from './InstallmentRecapStatus.style';
import { InstallmentRatios } from 'src/services/getOrderDetails/getOrderDetails.types';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import Translate from '../Translate/Translate.component';

interface installmentsRecapStatusComponentProps {
  progressBarProps: ProgressBarProps;
  installmentRatios: InstallmentRatios;
}

export const InstallmentsRecapStatusComponent: React.FC<
  installmentsRecapStatusComponentProps
> = ({ progressBarProps, installmentRatios }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();

  return (
    <>
      <ProgressBarComponent
        progress={progressBarProps.progress}
      ></ProgressBarComponent>

      {installmentRatios.Paid > 0 && (
        <LinkDetailInfoContainer
          align="center"
          justify="space-between"
          padding="1.2rem"
          marginBottom="0.4rem"
          innerColor={colors.greenStatus}
          borderColor={colors.greenStatus}
        >
          <PSmall bold marginRight="auto">
            <Translate id="lbl.paidStatus" />
          </PSmall>
          <H5 textColor={colors.greenStatus}>
            {formatCurrency(installmentRatios.PaidAmount)}
          </H5>
        </LinkDetailInfoContainer>
      )}

      {installmentRatios.Processing > 0 && (
        <LinkDetailInfoContainer
          align="center"
          justify="space-between"
          padding="1.2rem"
          marginBottom="0.4rem"
          innerColor={colors.white}
          borderColor={colors.greenStatus}
        >
          <PSmall bold marginRight="auto">
            <Translate id="lbl.processing" />
          </PSmall>
          <H5 textColor={colors.textColorGrey}>
            {formatCurrency(installmentRatios.ProcessingAmount)}
          </H5>
        </LinkDetailInfoContainer>
      )}

      {installmentRatios.Overdue > 0 && (
        <LinkDetailInfoContainer
          align="center"
          justify="space-between"
          padding="1.2rem"
          marginBottom="0.4rem"
          innerColor={colors.redStatus}
          borderColor={colors.redStatus}
        >
          <PSmall bold marginRight="auto">
            <Translate id="lbl.expired" />
          </PSmall>
          <H5 textColor={colors.redStatus}>
            {formatCurrency(installmentRatios.OverdueAmount)}
          </H5>
        </LinkDetailInfoContainer>
      )}

      {installmentRatios.Unpaid > 0 && (
        <LinkDetailInfoContainer
          align="center"
          justify="space-between"
          padding="1.2rem"
          marginBottom="0.4rem"
          innerColor={colors.textColorLightGrey}
          borderColor={colors.textColorLightGrey}
        >
          <PSmall bold marginRight="auto">
            <Translate id="lbl.toPay" />
          </PSmall>
          <H5 textColor={colors.textColorGrey}>
            {formatCurrency(installmentRatios.UnpaidAmount)}
          </H5>
        </LinkDetailInfoContainer>
      )}
    </>
  );
};
