import styled from 'styled-components';
import { Flex } from 'src/style/flexbox.style';
import colors from 'src/style-utils/colors';
import media from 'src/style-utils/media-queries';
import { H2, H3 } from 'src/style-utils/typographic';

export const Section = styled(Flex)`
  flex-direction: column;
  padding: 3.2rem 2.4rem;
  align-items: center;
  align-self: stretch;
  background-color: white;
  justify-content: center;
  display: flex;
  gap: 1.6rem;

  ${media.tablet`
  flex-direction: row;
  padding: 4rem 0;
  align-items: flex-start;
  align-self: stretch;
  background-color: white;
  justify-content: center;  
  gap: 3.2rem;
  `}
`;

export const OrderDatasContainer = styled(Flex)`
  width: 32.7rem;
  height: 22rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;

  ${media.tablet`
  width: 56rem;
  height: 23.4rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  `}
`;

export const BalanceContainer = styled(Flex)`
  height: 8.8rem;
  flex-direction: column;
  align-items: flex-start;

  ${media.tablet`
    height: 12.6rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  `}
`;

export const BalanceDisclaimerContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  align-self: stretch;
`;

export const PendingIncomeContainer = styled(Flex)`
  width: 32.7rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  border-top: 1px solid ${colors.greyBorder};
  border-left: 0;
  padding-top: 1.6rem;

  ${media.tablet`
    width: 24rem;
    height: 23.4rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.2rem;
    border-left: 1px solid ${colors.greyBorder};
    border-top: 0;
    padding-left: 2.4rem;
  `}
`;

export const PendingIncomeContentContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
  flex-shrink: 0;
  align-self: stretch;

  ${media.tablet`
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;
  margin-right: 2.4rem;
  `}
`;

export const CustomTitle = styled(H2)`
  color: ${colors.textColorGrey};
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8rem;

  ${media.tablet`
  color: ${colors.textColorGrey};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.6rem;
  `}
`;

export const PendingIncomeTitle = styled(H2)`
  color: ${colors.textColorGrey};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
`;

export const CustomCurrentBalance = styled(H3)`
  color: ${colors.textColorBlack};
  font-size: 4.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 6rem;

  ${media.tablet`
  color: ${colors.textColorBlack};
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 9rem;
  `}
`;

export const CustomDisclaimer = styled.span`
  color: ${colors.textColorGrey};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  a {
    color: ${colors.textColorGrey};
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4rem;
    text-decoration-line: underline;
  }

  ${media.tablet`
  color: ${colors.textColorGrey};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  a {
    color: ${colors.textColorGrey};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.4rem;
    text-decoration-line: underline;
  }
`}
`;

export const CustomPendingBalance = styled(H3)`
  color: ${colors.textColorBlack};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.6rem;
`;

export const CustomHyperlink = styled.a`
  color: ${colors.textColorGrey};
  text-overflow: ellipsis;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.2rem;
  text-decoration-line: underline;
  cursor: pointer;
`;
