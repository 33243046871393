import DataTable from 'src/components/DataTable/DataTable';
import { DataTableProps } from 'src/components/DataTable/DataTable.types';
import Loader from 'src/components/Loader/Loader.component';
import { GetBuyerStatsDataResponse } from 'src/services/monitoring/monitoring.types';
import { installmentsTableColumnConfig } from './TableInstallments.config';

interface Props {
  data?: GetBuyerStatsDataResponse;
  isLoading: boolean;
}

const TableInstallments = ({ data, isLoading }: Props) => {
  const tabProps: DataTableProps<GetBuyerStatsDataResponse> = {
    data: data?.ResultSet.InstallmentsStats ?? [],
    hideHeader: false,
    NoDataComponent: () => {},
    columns: installmentsTableColumnConfig,
    customTableTheme: {
      cells: {
        style: {
          ':first-child': {
            background: '#fff',
            display: 'block',
          },
        },
      },
    },
  };

  if (isLoading)
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;

  return <DataTable {...tabProps} />;
};

export default TableInstallments;
