import breakpoints from 'src/style-utils/breakpoints';
import { modalTheme } from 'src/components/Modal/Modal.theme';
import selectTheme from 'src/components/Select/Select.theme';
import checkboxTheme from 'src/components/Checkbox/Checkbox.theme';
import radioboxTheme from './components/RadioBox/RadioBox.theme';
import { defaultDataTableTheme } from 'src/components/DataTable/DataTable.theme';

const appTheme = {
  zIndexes: {
    devOnlyDashBoard: 999,
    sidebarNavContent: 2,
    footerOverlay: 100,
    navbarWrapper: 50,
    modal: {
      overlay: 999999,
    },

    listbox: {
      button: 12,
      list: 11,
      // Set it higher than `listboxButton` if you want it rendered above
      // when expanded.
      listExpanded: 14,
    },
    drawer: {
      overlay: 500,
      slider: 501,
    },
  },
  floatingButton: {
    height: '6.8rem',
  },
  ...selectTheme,
  ...modalTheme,
  ...checkboxTheme,
  ...radioboxTheme,
  ...defaultDataTableTheme,
};

// Styled Boostrap Grid Theme.
// see: https://github.com/dragma/styled-bootstrap-grid#advanced
const gridTheme = { breakpoints };

if (process.env.NODE_ENV !== 'production') {
  Object.freeze(appTheme);
  Object.freeze(gridTheme);
}

export type AppTheme = Readonly<typeof appTheme>;
export type GridTheme = Readonly<typeof gridTheme>;

export default appTheme;

export { gridTheme };
