import { Button } from 'src/components/Button/Button.component';
import { ButtonProps } from 'src/components/Button/Button.types';
import ModalHumanChatLoadable from 'src/components/ModalHumanChat/ModalHumanChat.loadable';
import React, { useEffect, useState } from 'react';
import { useLanguage } from 'src/services/i18n/i18n.context';

type NeedHelpButtonProps = ButtonProps & {
  BaseComponent?: React.ComponentType<any>;
};

const NeedHelpBase = React.memo(function InputField({
  id,
  className,
  BaseComponent = Button,
  disabled,
  ...otherProps
}: NeedHelpButtonProps) {
  const [modal, setModal] = useState(false);
  const canChat = typeof (window as any).zE !== 'undefined';
  const { language } = useLanguage();

  const showCookieBanner = () => {
    Cookiebot?.show();
    window.addEventListener(
      'CookiebotOnAccept',
      function (e) {
        window.location.reload();
      },
      false,
    );
  };

  const showHumanChat = () => {
    (window as any).zE.setLocale(language);
    (window as any).zE('webWidget', 'show');
    (window as any).zE('webWidget', 'open');
    setModal(false);
  };

  useEffect(() => {
    return () => {
      if (typeof (window as any).zE !== 'undefined') {
        (window as any)?.zE('webWidget', 'hide');
      }
    };
  }, []);

  return (
    <>
      <BaseComponent {...otherProps} onClick={() => setModal(true)} />
      {modal && (
        <ModalHumanChatLoadable
          i18n
          modalTitle="supportModal.Title"
          handleClose={() => setModal(false)}
          canChat={canChat}
          showHumanChat={showHumanChat}
          showCookieBanner={showCookieBanner}
        />
      )}
    </>
  );
});

export default NeedHelpBase;
