import { Button } from 'src/components/Button/Button.component';
import { ModalHelperProps } from 'src/components/Modal/Modal.types';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { ModalFooterRoot } from './style/ModalFooterDefault.style';

export type ModalFooterProps = ModalHelperProps & {
  handleClose: () => void;
  buttonTitle: string;
};

const ModalFooterDefault: React.FC<ModalFooterProps> = ({
  handleClose,
  buttonTitle,
  ...otherProps
}) => {
  return (
    <ModalFooterRoot>
      <Button
        minWidthMobile="100%"
        sizeOnDesktop="medium"
        {...otherProps}
        onClick={handleClose}
      >
        <Translate id={buttonTitle} />
      </Button>
    </ModalFooterRoot>
  );
};

export default ModalFooterDefault;
