import { Button } from 'src/components/Button/Button.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as CustomProductIcon } from 'src/images/products/product_cst.svg';
import { ResetPayByLinkStore } from 'src/pages/createLinkFlow/CreateLinkFlow.config';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { initOrderCreateMethodApi } from 'src/services/orderLink/orderLink.request';
import { usePayByLinkStore } from 'src/store/store';
import { H4, PSmall } from 'src/style-utils/typographic';
import { CategoryCodes, ProductCodes } from 'src/utils/types/common.types';
import type { Prospect } from './ProductCard.types';
import ProductCardCSTModalComponent from './modal/ProductCardCSTModal.component';

type CustomDetails = {
  InstallmentsCount: number;
  Name: string;
  Prospect: Prospect[];
};

type Props = {
  categoryCode: CategoryCodes;
  productCode: ProductCodes;
  customDetail: CustomDetails;
  disabled: boolean;
  fromMonitoring?: string;
};

const ProductCardCST = ({
  productCode,
  categoryCode,
  customDetail,
  disabled,
  fromMonitoring,
}: Props) => {
  const setValue = usePayByLinkStore((state) => state.setValue);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const setValues = usePayByLinkStore((state) => state.setValues);
  const title = customDetail.Name;
  const prospect = customDetail.Prospect;
  const { mutate: mutateInit, isLoading: isLoadingInit } = useMutation(
    initOrderCreateMethodApi,
    {
      onSuccess: (res) => {
        setValue('stateFlow', true);
        setValue('uwId', res?.data?.ResultSet.UwId);
        setValue('sessionGuid', res?.data?.ResultSet.Guid);
        setValue('inviteEmail', res?.data?.ResultSet.LastInviteEmail);
        navigate(
          `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepImport}`,
        );
      },
      onError: (error: any) => {},
    },
  );
  if (isLoadingInit) {
    return <Loader overlayViewMode="fullscreen" active viewMode="fluid" />;
  }
  return (
    <>
      <div className="flex min-h-[56rem] w-70 flex-col overflow-hidden rounded-xl bg-white shadow-blue4px lg:min-h-[60rem] lg:w-96 lg:shadow-blue8px">
        <div className="flex h-40 items-center bg-pblack-400 px-6 py-8">
          <H4 textColor="white">{title}</H4>
        </div>
        <div className="flex flex-1 flex-col gap-4 px-4 py-6 lg:gap-8 lg:px-6 lg:py-8">
          <CustomProductIcon
            width={198}
            height={176}
            className="h-36 w-auto lg:h-44"
          />
          <div className="flex flex-1 flex-col gap-3">
            <PSmall colorBlack>
              <Translate id="lbl.totalInstallment" />
              :&nbsp;
              {customDetail.InstallmentsCount}
            </PSmall>
            <PSmall colorBlack>
              <Translate id="text.rataPlanning" />:
              <Button
                underline
                variant="LinkPrimary"
                padding="0"
                margin="0"
                height="max-content"
                fontWeight="400"
                onClick={() => setShowModal(true)}
              >
                <PSmall colorBlack>
                  <Translate id="lbl.seeDetail" />
                </PSmall>
              </Button>
            </PSmall>
          </div>
          <Button
            variant="Tertiary"
            minWidth="100%"
            sizeOnDesktop="medium"
            disabled={disabled}
            onClick={() => {
              setValues(ResetPayByLinkStore);
              if (fromMonitoring) {
                mutateInit({ BuyerGuid: fromMonitoring });
                return;
              }
              navigate(
                `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCustomer}`,
              );
            }}
          >
            <Translate id="text.createLink" />
          </Button>
        </div>
      </div>
      {showModal && (
        <ProductCardCSTModalComponent
          buttonTitle="text.okUnderstand"
          modalTitle="text.rataPlanning"
          prospect={prospect}
          handleClose={() => setShowModal(false)}
          i18n
        />
      )}
    </>
  );
};

export default ProductCardCST;
