import { ReactComponent as AdvancePaymentIcon } from 'src/images/products/product_adv_3_67.svg';
import { ReactComponent as FlexEqualIcon } from 'src/images/products/product_flex_eq.svg';
import { ReactComponent as FlexCustomIcon } from 'src/images/products/product_flx_custom.svg';
import { ReactComponent as SingleDelayedIcon } from 'src/images/products/product_sgl_dly.svg';
import { ReactComponent as SingleInstantIcon } from 'src/images/products/product_sgl_ins.svg';
import { IDs } from 'src/utils/config/ids';
import { ProductCodes, ProductsEnum } from 'src/utils/types/common.types';
import React from 'react';

type TProductCardArray = {
  [key in ProductCodes]: {
    title: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    subtitle1: string;
    subtitle2?: string;
    id?: string;
  };
};

export const ProductCardArray: TProductCardArray = {
  [ProductsEnum.advancePayment]: {
    title: 'product.ADV_3_67.title',
    icon: AdvancePaymentIcon,
    subtitle1: 'product.ADV_3_67.description1',
    subtitle2: 'product.ADV_3_67.description2',
    id: undefined,
  },
  [ProductsEnum.singleInstant]: {
    title: 'product.SGL_INS.title',
    icon: SingleInstantIcon,
    subtitle1: 'product.SGL_INS.description1',
    id: undefined,
  },
  [ProductsEnum.singleDelayed]: {
    title: 'product.SGL_DLY.title',
    icon: SingleDelayedIcon,
    subtitle1: 'product.SGL_DLY.description1',
    id: undefined,
  },
  [ProductsEnum.flexEqual]: {
    title: 'product.FLX_EQ.title',
    icon: FlexEqualIcon,
    subtitle1: 'product.FLX_EQ.description1',
    subtitle2: 'product.FLX_EQ.description2',
    id: IDs.btnFlxEq,
  },
  [ProductsEnum.flexCustom]: {
    title: 'product.FLX_CUSTOM.title',
    icon: FlexCustomIcon,
    subtitle1: 'product.FLX_CUSTOM.description1',
    subtitle2: 'product.FLX_CUSTOM.description2',
    id: IDs.btnFlxCustom,
  },
  [ProductsEnum.custom]: {
    title: 'product.FLX_CUSTOM.title',
    icon: FlexCustomIcon,
    subtitle1: 'product.FLX_CUSTOM.description1',
    subtitle2: 'product.FLX_CUSTOM.description2',
    id: undefined,
  },
  [ProductsEnum.remodulation]: {
    title: 'product.FLX_CUSTOM.title',
    icon: FlexCustomIcon,
    subtitle1: 'product.FLX_CUSTOM.description1',
    subtitle2: 'product.FLX_CUSTOM.description2',
    id: undefined,
  },
};
