import apiRequest from 'src/services/api/api.config';
import { makeEndpointApiConfig } from 'src/services/api/api.hooks';
import { balanceAndMovementsInitialDataEndPoint } from './balanceAndMovementsInitialData.config';

export const getBalanceAndMovementsInitialDataMethodApi = async () => {
  const requestConfig = makeEndpointApiConfig({
    url: balanceAndMovementsInitialDataEndPoint,
    method: 'GET',
    authenticated: true,
  });
  return await apiRequest.request(requestConfig);
};
