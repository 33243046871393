import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { H5 } from 'src/style-utils/typographic';
import CheckoutForm from './partials/CheckoutForm.component';
import { STRIPE_PROMISE } from 'src/settings';

const AddMethodStripePage: React.FC = () => {
  const location = useLocation();
  const clientSecretStripe = location.state?.clientSecretStripe;
  const stripePromise = loadStripe(`${STRIPE_PROMISE}`);
  const { language } = useLanguage();

  const options: StripeElementsOptions = {
    clientSecret: clientSecretStripe,
    locale: language,
  };

  return (
    <div className="w-full p-6 lg:px-12 lg:py-9">
      <GoBackButton noLabel />
      <H5 marginBottom="2.4rem">
        <Translate id="text.addData" />
      </H5>
      <Elements
        stripe={stripePromise}
        options={options}
        data-cookieconsent="ignore"
      >
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default AddMethodStripePage;
