import { MainLayout } from 'src/components/MainLayout/MainLayout.componet';
import { ProtectedRoute } from 'src/components/ProtectedRoute/ProtectedRoute.component';
import UploadedContractImage from 'src/images/pageStatus/contract.svg?url';
import ImgFileSaved from 'src/images/pageStatus/file_save.svg?url';
import ADVPaymentFlow from 'src/pages/AdvPaymentFlow/AdvPaymentFlow.component';
import ADVPayResultPage from 'src/pages/AdvPaymentFlow/partials/AdvPayResultPage/AdvPayResultPage.component';
import ChooseLinkPage from 'src/pages/AdvPaymentFlow/partials/ChooseLinkPage/ChooseLinkPage.component';
import LinkDetail from 'src/pages/AdvPaymentFlow/partials/LinkDetail/LinkDetail.component';
import RequestsResults from 'src/pages/AdvPaymentFlow/partials/RequestsResults/RequestsResults.component';
import PayByLinkActionsSupport from 'src/pages/PayByLinkDetailPage/PayByLinkActionsSupport/PayByLinkActionsSupport.component';
import PayByLinkDetailPage from 'src/pages/PayByLinkDetailPage/PayByLinkDetailPage.loadable';
import BeforeEditInstallmentPlan from 'src/pages/PayByLinkDetailPage/partials/BeforeEditInstallmentPlan/BeforeEditInstallmentPlan.component';
import PayByLinkActions from 'src/pages/PayByLinkDetailPage/partials/PayByLinkActions/PayByLinkActions.component';
import PayByLinkActionsQR from 'src/pages/PayByLinkDetailPage/partials/PayByLinkActionsQR/PayByLinkActionsQR.component';
import PayByLinkActionsResendLink from 'src/pages/PayByLinkDetailPage/partials/PayByLinkActionsResendLink/PayByLinkActionsResendLink.component';
import PayByLinkActionsSuccessResend from 'src/pages/PayByLinkDetailPage/partials/PayByLinkActionsSuccessResend/PayByLinkActionsSuccessResend.component';
import PayByLinkDetailHelp from 'src/pages/PayByLinkDetailPage/partials/PayByLinkDetailHelp/PayByLinkDetailHelp.component';
import PayByLinkDetailInfo from 'src/pages/PayByLinkDetailPage/partials/PayByLinkDetailInfo/PayByLinkDetailInfo.component';
import PayByLinkSuccessCanceled from 'src/pages/PayByLinkDetailPage/partials/PayByLinkSuccessCanceled/PayByLinkSuccessCanceled.component';
import PayByLinkSuccessRemodulation from 'src/pages/PayByLinkDetailPage/partials/PayByLinkSuccessRemodulation/PayByLinkSuccessRemodulation.component';
import PayByLinkActionsSendLinkNow from 'src/pages/PayByLinkDetailPage/partials/payByLinkActionsSendLinkNow/PayByLinkActionsSendLinkNow.component';
import PurchaseFlow from 'src/pages/PurchaseFlow/PurchaseFlow.component';
import ThankYouPage from 'src/pages/ThankyouPage/ThankYouPage.loadable';
import SuccessChangeEmail from 'src/pages/ThankyouPage/partials/SuccessChangeEmail.component';
import SuccessChangePassword from 'src/pages/ThankyouPage/partials/SuccessChangePassword.component';
import SuccessChangeNumber from 'src/pages/ThankyouPage/partials/SuccessChangePhoneNumber.component';
import SuccessRecoveryPassword from 'src/pages/ThankyouPage/partials/SuccessRecoveryPassword.component';
import SuccessRegistration from 'src/pages/ThankyouPage/partials/SuccessRegistration.component';
import AccessData from 'src/pages/accessDataPage/AccessData.loadable';
import Account from 'src/pages/account/Account.component';
import AddCollaborator from 'src/pages/addCollaborator/AddCollaborator.component';
import SuccessAddCollaborator from 'src/pages/addCollaborator/SuccessAddCollaborator.component';
import AdvancePaymentInfo from 'src/pages/advancePaymentInfo/AdvancePaymentInfo.component';
import AdvancePlanRequests from 'src/pages/advancePlanRequests/AdvancePlanRequests.component';
import BalanceMovementsPage from 'src/pages/balanceMovementsPage/BalanceMovementsPage.component';
import ChangeAccessData from 'src/pages/changeAccessData/ChangeAccessData.component';
import ChangeEmail from 'src/pages/changeAccessData/partials/changeEmail/ChangeEmail.component';
import ChangePassword from 'src/pages/changeAccessData/partials/changePassword/ChangePassword.component';
import ChangePhone from 'src/pages/changeAccessData/partials/changePhone/ChangePhone.component';
import OtpAuth from 'src/pages/changeAccessData/partials/otp/OtpAuth.component';
import OtpPerform from 'src/pages/changeAccessData/partials/otp/OtpPerform.component';
import Collaborator from 'src/pages/collaborator/Collaborator.component';
import CollaboratorsPage from 'src/pages/collaboratorsPage/CollaboratorsPage.loadable';
import ContinueRegistration from 'src/pages/continueRegistration/ContinueRegistration.component';
import CollaboratorsInfoPage from 'src/pages/contributorsInfoPage/CollaboratorsInfoPage.component';
import CreateLinkFlow from 'src/pages/createLinkFlow/CreateLinkFlow.component';
import ChooseCategory from 'src/pages/createLinkFlow/partials/ChooseCategory/ChooseCategory.component';
import ChooseCompany from 'src/pages/createLinkFlow/partials/ChooseCompany/ChooseCompany.component';
import ChooseProduct from 'src/pages/createLinkFlow/partials/ChooseProduct/ChooseProduct.component';
import DateInstallmentPlan from 'src/pages/createLinkFlow/partials/DateInstallmentPlan/DateInstallmentPlan.component';
import SendLinkNow from 'src/pages/createLinkFlow/partials/SendLinkNow/SendLinkNow.component';
import ShowQrCode from 'src/pages/createLinkFlow/partials/ShowQrCode/ShowQrCode.component';
import StartInstallmentPlan from 'src/pages/createLinkFlow/partials/StartInstallmentPlan/StartInstallmentPlan.component';
import StepCf from 'src/pages/createLinkFlow/partials/StepCf/StepCf.component';
import StepCheckData from 'src/pages/createLinkFlow/partials/StepCheckData/StepCheckData.component';
import StepCollectionMethod from 'src/pages/createLinkFlow/partials/StepCollectionMethod/StepCollectionMethod.component';
import StepCountry from 'src/pages/createLinkFlow/partials/StepCountry/StepCountry.component';
import StepCreditResume from 'src/pages/createLinkFlow/partials/StepCreditResume/StepCreditResume.component';
import StepCustomInstallmentForm from 'src/pages/createLinkFlow/partials/StepCustomInstallmentForm/StepCustomInstallmentForm.component';
import StepCustomer from 'src/pages/createLinkFlow/partials/StepCustomer/StepCustomer.component';
import StepCustomerValidation from 'src/pages/createLinkFlow/partials/StepCustomerValidation/StepCustomerValidation.component';
import StepDescription from 'src/pages/createLinkFlow/partials/StepDescription/StepDescription.component';
import StepEmail from 'src/pages/createLinkFlow/partials/StepEmail/StepEmail.component';
import StepImport from 'src/pages/createLinkFlow/partials/StepImport/StepImport.component';
import StepInstallmentAmount from 'src/pages/createLinkFlow/partials/StepInstallmentAmount/StepInstallmentAmount.component';
import StepInstallmentDelay from 'src/pages/createLinkFlow/partials/StepInstallmentDelay/StepInstallmentDelay.component';
import StepInstallmentNumber from 'src/pages/createLinkFlow/partials/StepInstallmentNumber/StepInstallmentNumber.component';
import StepInstallmentPlan from 'src/pages/createLinkFlow/partials/StepInstallmentPlan/StepInstallmentPlan.component';
import StepInvoiceDate from 'src/pages/createLinkFlow/partials/StepInvoiceDate/StepInvoiceDate.component';
import StepName from 'src/pages/createLinkFlow/partials/StepName/StepName.component';
import StepNamePostalCode from 'src/pages/createLinkFlow/partials/StepNamePostalCode/StepNamePostalCode.component';
import StepResume from 'src/pages/createLinkFlow/partials/StepResume/StepResume.component';
import StepSelectTemplates from 'src/pages/createLinkFlow/partials/StepSelectTemplates/StepSelectTemplates.component';
import StepSendLink from 'src/pages/createLinkFlow/partials/StepSendLink/StepSendLink.component';
import StepVAT from 'src/pages/createLinkFlow/partials/StepVAT/StepVAT.component';
import SuccessSendLink from 'src/pages/createLinkFlow/partials/SuccessSendLink/SuccessSendLink.component';
import DevOnlyTestPage from 'src/pages/devOnlyTestPage/DevOnlyTestPage.loadable';
import DocumentsPage from 'src/pages/documentsPage/DocumentsPage.component';
import Ecommerce from 'src/pages/ecommerce/Ecommerce.component';
import EcommerceCheckData from 'src/pages/ecommerce/partials/ecommerceCheckData/EcommerceCheckData.component';
import ECommerceCheckout from 'src/pages/ecommerce/partials/ecommerceCheckout/ECommerceCheckout.component';
import AlreadyPaidPlugin from 'src/pages/ecommerce/partials/ecommerceCheckout/partials/AlreadyPaidPlugin/AlreadyPaidPlugin.component';
import PluginPaymentSuccessPage from 'src/pages/ecommerce/partials/ecommerceCheckout/partials/paymentSuccessPage/PaymentSuccessPage.component';
import PluginSetupSuccessPage from 'src/pages/ecommerce/partials/ecommerceCheckout/partials/setupSuccessPage/SetupSuccessPage.component';
import EcommerceInfo from 'src/pages/ecommerceInfo/EcommerceInfo.component';
import ErrorPage from 'src/pages/errorPage/ErrorPage.component';
import { default as FailedPage } from 'src/pages/failedPage/FailedPage.loadable';
import BuyerFiscalCodeError from 'src/pages/failedPage/partials/BuyerFiscalCodeError.component';
import CanceledLink from 'src/pages/failedPage/partials/CanceledLink.component';
import CreateLinkError from 'src/pages/failedPage/partials/CreateLinkError.component';
import EcommerceWrongData from 'src/pages/failedPage/partials/EcommerceWrongData.component';
import ExpiredCart from 'src/pages/failedPage/partials/ExpiredCart.component';
import ExpiredLink from 'src/pages/failedPage/partials/ExpiredLink.component';
import FiscalCodeMatchError from 'src/pages/failedPage/partials/FiscalCodeMatchError.component';
import InternalError from 'src/pages/failedPage/partials/InternalError.component';
import LinkAlreadyPaid from 'src/pages/failedPage/partials/LinkAlreadyPaid.component';
import LoginBuyerError from 'src/pages/failedPage/partials/LoginBuyerError.component';
import NotMyCompany from 'src/pages/failedPage/partials/NotMyCompany.component';
import PaymentError from 'src/pages/failedPage/partials/PaymentError.component';
import PaymentFailed from 'src/pages/failedPage/partials/PaymentFailed/PaymentFailed.component';
import SignGenericError from 'src/pages/failedPage/partials/SignGenericError.component';
import SignWrongUser from 'src/pages/failedPage/partials/SignWrongUser.component';
import UWBuyerError from 'src/pages/failedPage/partials/UWBuyerError.component';
import Installments from 'src/pages/installments/Installments.component';
import IsYourCompany from 'src/pages/isYourCompany/IsYourCompany.component';
import LandingPage from 'src/pages/landingPage/LandingPage.component';
import LoadingNamirial from 'src/pages/loadingNamirial/LoadingNamirial.component';
import Login from 'src/pages/login/Login.component';
import MarketingChoice from 'src/pages/marketingChoice/MarketingChoice.component';
import Monitoring from 'src/pages/monitoring/Monitoring.component';
import MonitoringBuyerDetail from 'src/pages/monitoringBuyerDetail/MonitoringBuyerDetail.component';
import OnboardingFlow from 'src/pages/onboardingFlow/OnboardingFlow.component';
import Assistance from 'src/pages/onboardingFlow/partials/assistance/Assistance.component';
import ErrorKoAdmin from 'src/pages/onboardingFlow/partials/errorKoAdmin/ErrorKoAdmin.component';
import ReadContract from 'src/pages/onboardingFlow/partials/readContract/ReadContract.component';
import SignStripe from 'src/pages/onboardingFlow/partials/signStripe/SignStripe.component';
import SignWithSpid from 'src/pages/onboardingFlow/partials/signWithSpid/SignWithSpid.component';
import SuccessNamirial from 'src/pages/onboardingFlow/partials/successNamirial/SuccessNamirial.component';
import SuccessStripe from 'src/pages/onboardingFlow/partials/successStripe/SuccessStripe.component';
import OrderDetail from 'src/pages/orderDetail/OrderDetail.loadable';
import MultiplePaymentPage from 'src/pages/orderDetail/partials/MultiplePayment.component';
import OrderDetailHelp from 'src/pages/orderDetail/partials/OrderDetailHelp.component';
import OrderDetailInfo from 'src/pages/orderDetail/partials/OrderDetailInfo.component';
import Orders from 'src/pages/orders/Orders.loadable';
import PayByLinkPage from 'src/pages/payByLinkPage/payByLinkPage.component';
import PaymentFlowPage from 'src/pages/paymentFlow/PaymentFlowPage.loadable';
import ChoosePaymentMethodPage from 'src/pages/paymentFlow/partials/choosePaymentMethodPage/ChoosePaymentMethodPage.component';
import MultiPaymentSuccessPage from 'src/pages/paymentFlow/partials/multiPaymentSuccessPage/MultiPaymentSuccessPage.component';
import PayWithOpynPage from 'src/pages/paymentFlow/partials/payWithOpyn/PayWithOpynPage.component';
import PaymentSuccessPage from 'src/pages/paymentFlow/partials/paymentSuccessPage/PaymentSuccessPage.component';
import SetBackupPage from 'src/pages/paymentFlow/partials/setBackup/SetBackup.component';
import SetMethodPage from 'src/pages/paymentFlow/partials/setMethodsPage/SetMethodsPage.component';
import SetupSuccessPage from 'src/pages/paymentFlow/partials/setupSuccessPage/SetupSuccessPage.component';
import PaymentCard from 'src/pages/paymentFlow/partials/stripePage/StripePage.component';
import PaymentMethodsFlow from 'src/pages/paymentMethods/PaymentMethodsFlow.component';
import AddBackupMethodPage from 'src/pages/paymentMethods/partials/addBackupMethodPage/AddBackupMethodPage.component';
import AddMethodStripePage from 'src/pages/paymentMethods/partials/addMethodStripePage/AddMethodStripePage.component';
import ChooseMethodPage from 'src/pages/paymentMethods/partials/chooseMethodPage/ChooseMethodPage.component';
import PaymentMethodsPage from 'src/pages/paymentMethods/partials/paymentMethodsPage/PaymentMethodsPage.component';
import SetBackupMethodPage from 'src/pages/paymentMethods/partials/setBackupMethodPage/SetBackupMethodPage.component';
import PaymentSummary from 'src/pages/paymentSummary/PaymentSummary.component';
import PersonalData from 'src/pages/personalData/PersonalData.lodable';
import PrivateArea from 'src/pages/privateArea/PrivateArea.loadable';
import RecoveryData from 'src/pages/recoveryData/RecoveryData.component';
import ShowEmail from 'src/pages/recoveryData/partials/ShowEmail/ShowEmail.component';
import InsertPassword from 'src/pages/recoveryData/partials/changePassword/InsertPassword.component';
import ConfirmOtp from 'src/pages/recoveryData/partials/confirmOtp/ConfirmOtp.component';
import InsertOtp from 'src/pages/recoveryData/partials/confirmOtp/InsertOtp.component';
import RecoveryEmail from 'src/pages/recoveryData/partials/recoveryEmail/RecoveryEmail.component';
import RecoveryPassword from 'src/pages/recoveryData/partials/recoveryPassword/RecoveryPassword.component';
import RedirectStripe from 'src/pages/redirectStripe/RedirectStripe.component';
import RegistrationBuyer from 'src/pages/registration/registrationBuyer/RegistrationBuyer.component';
import EmailStepRegistration from 'src/pages/registration/registrationBuyer/partials/emailStep/EmailStepRegistration.component';
import FlagStepRegistration from 'src/pages/registration/registrationBuyer/partials/flagStep/FlagStepRegistration.component';
import OtpConfirmEmailStepRegistration from 'src/pages/registration/registrationBuyer/partials/otpConfirmEmailStep/OtpConfirmEmailStepRegistration.component';
import OtpConfirmPhoneStepRegistration from 'src/pages/registration/registrationBuyer/partials/otpConfirmPhoneStep/OtpConfirmPhoneStepRegistration.component';
import PasswordStepRegistration from 'src/pages/registration/registrationBuyer/partials/passwordStep/PasswordStepRegistration.component';
import PhoneStepRegistration from 'src/pages/registration/registrationBuyer/partials/phoneStep/PhoneStepRegistration.component';
import RegistrationCollaborator from 'src/pages/registration/registrationCollaborator/RegistrationCollaborator.component';
import RegistrationCollaboratorStepFlag from 'src/pages/registration/registrationCollaborator/partials/RegistrationCollaboratorStepFlag/RegistrationCollaboratorStepFlag.component';
import RegistrationCollaboratorStepOtpPhone from 'src/pages/registration/registrationCollaborator/partials/RegistrationCollaboratorStepOtp/RegistrationCollaboratorStepOtpPhone.component';
import RegistrationCollaboratorStepPassword from 'src/pages/registration/registrationCollaborator/partials/RegistrationCollaboratorStepPassword/RegistrationCollaboratorStepPassword.component';
import RegistrationCollaboratorStepPhone from 'src/pages/registration/registrationCollaborator/partials/RegistrationCollaboratorStepPhone/RegistrationCollaboratorStepPhone.component';
import RegistrationCollaboratorSuccessPage from 'src/pages/registration/registrationCollaborator/partials/RegistrationCollaboratorSuccessPage/RegistrationCollaboratorSuccessPage.component';
import RegistrationGuestLoadable from 'src/pages/registration/registrationGuest/RegistrationGuest.loadable';
import RegistrationGuestStepCheckData from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepCheckData/RegistrationGuestStepCheckData.component';
import RegistrationGuestStepEmail from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepEmail/RegistrationGuestStepEmail.component';
import RegistrationGuestStepFlag from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepFlag/RegistrationGuestStepFlag.component';
import RegistrationGuestStepOtpEmail from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepOtpEmail/RegistrationGuestStepOtpEmail.component';
import RegistrationGuestStepOtpPhone from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepOtpPhone/RegistrationGuestStepOtpPhone.component';
import RegistrationGuestStepPassword from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepPassword/RegistrationGuestStepPassword.component';
import RegistrationGuestStepPhone from 'src/pages/registration/registrationGuest/partials/RegistrationGuestStepPhone/RegistrationGuestStepPhone.component';
import RegistrationGuestSuccess from 'src/pages/registration/registrationGuest/partials/RegistrationGuestSuccess/RegistrationGuestSuccess.component';
import RegistrationLight from 'src/pages/registration/registrationLight/RegistrationLight.loadable';
import RegistrationLightStepCheckData from 'src/pages/registration/registrationLight/partials/RegistrationLightStepCheckData/RegistrationLightStepCheckData.component';
import RegistrationLightStepFlag from 'src/pages/registration/registrationLight/partials/RegistrationLightStepFlag/RegistrationLightStepFlag.component';
import RegistrationLightStepOtpPhone from 'src/pages/registration/registrationLight/partials/RegistrationLightStepOtpPhone/RegistrationLightStepOtpPhone.component';
import RegistrationLightStepPhone from 'src/pages/registration/registrationLight/partials/RegistrationLightStepPhone/RegistrationLightStepPhone.component';
import RegistrationMerchantLoadable from 'src/pages/registration/registrationMerchant/RegistrationMerchant.loadable';
import RegistrationChooseCompany from 'src/pages/registration/registrationMerchant/partials/RegistrationChooseCompany/RegistrationChooseCompany.component';
import RegistrationStepCf from 'src/pages/registration/registrationMerchant/partials/RegistrationStepCf/RegistrationStepCf.component';
import RegistrationStepCheckData from 'src/pages/registration/registrationMerchant/partials/RegistrationStepCheckData/RegistrationStepCheckData.component';
import RegistrationStepCountry from 'src/pages/registration/registrationMerchant/partials/RegistrationStepCountry/RegistrationStepCountry.component';
import RegistrationStepEmail from 'src/pages/registration/registrationMerchant/partials/RegistrationStepEmail/RegistrationStepEmail.component';
import RegistrationStepFlag from 'src/pages/registration/registrationMerchant/partials/RegistrationStepFlag/RegistrationStepFlag.component';
import RegistrationStepName from 'src/pages/registration/registrationMerchant/partials/RegistrationStepName/RegistrationStepName.component';
import RegistrationStepNamePostalCode from 'src/pages/registration/registrationMerchant/partials/RegistrationStepNamePostalCode/RegistrationStepNamePostalCode.component';
import RegistrationStepOtpEmail from 'src/pages/registration/registrationMerchant/partials/RegistrationStepOtpEmail/RegistrationStepOtpEmail.component';
import RegistrationStepOtpPhone from 'src/pages/registration/registrationMerchant/partials/RegistrationStepOtpPhone/RegistrationStepOtpPhone.component';
import RegistrationStepPassword from 'src/pages/registration/registrationMerchant/partials/RegistrationStepPassword/RegistrationStepPassword.component';
import RegistrationStepPhone from 'src/pages/registration/registrationMerchant/partials/RegistrationStepPhone/RegistrationStepPhone.component';
import RegistrationStepVAT from 'src/pages/registration/registrationMerchant/partials/RegistrationStepVAT/RegistrationStepVAT.component';
import RegistrationCollaboratorLandingPage from 'src/pages/registrationCollaboratorLandingPage/RegistrationCollaboratorLandingPage.component';
import RegistrationMerchantLandingPage from 'src/pages/registrationMerchantLandingPage/RegistrationMerchantLandingPage.component';
import RemodulationInfo from 'src/pages/remodulationInfo/RemodulationInfo.component';
import RemodulationRequests from 'src/pages/remodulationRequests/RemodulationRequests.component';
import SaveTemplate from 'src/pages/saveTemplate/SaveTemplate.component';
import Support from 'src/pages/support/Support.component';
import UsageRequirements from 'src/pages/usageSetting/partials/usageRequirements/UsageRequirements.component';
import UsageSettingLiquidity from 'src/pages/usageSetting/partials/usageSettingLiquidity/usageSettingLiquidity.component';
import UsageSettingOptions from 'src/pages/usageSetting/partials/usageSettingOptions/usageSettingOptions.component';
import UsageSettingSavedTemplates from 'src/pages/usageSetting/partials/usageSettingSavedTemplates/usageSettingSavedTemplates.component';
import UsageSettingTemplate from 'src/pages/usageSetting/partials/usageSettingTemplate/UsageSettingTemplate.component';
import UsageSetting from 'src/pages/usageSetting/usageSetting.component';
import WelcomePage from 'src/pages/welcomePage/WelcomePage.component';
import { Navigate, RouteObject } from 'react-router-dom';
import { RoutePath } from './routers.config';
import PluginInfo from 'src/pages/pluginInfo/PluginInfo.component';
import { PayByLinkActionsResultEvaluationBuyer } from '../pages/PayByLinkDetailPage/partials/PayByLinkActionsResultEvaluationBuyer/PayByLinkActionsResultEvaluationBuyer.component';

const routerConfig: RouteObject[] = [
  {
    element: <MainLayout />,
    id: 'PUBLIC',
    children: [
      {
        path: RoutePath.root,
        element: <Navigate to={RoutePath.privateArea} />,
      },
      {
        path: RoutePath.login,
        element: <Login />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.registrationBuyer,
        element: <RegistrationBuyer />,
        children: [
          {
            path: RoutePath.emailStep,
            element: <EmailStepRegistration />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.passwordStep,
            element: <PasswordStepRegistration />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.otpConfirmEmailStep,
            element: <OtpConfirmEmailStepRegistration />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.phoneStep,
            element: <PhoneStepRegistration />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.otpConfirmPhoneStep,
            element: <OtpConfirmPhoneStepRegistration />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.flagStep,
            element: <FlagStepRegistration />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
        ],
      },
      {
        path: RoutePath.registrationLight,
        element: <RegistrationLight />,
        children: [
          {
            path: RoutePath.stepCheckData,
            element: <RegistrationLightStepCheckData />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.phoneStep,
            element: <RegistrationLightStepPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.otpConfirmPhoneStep,
            element: <RegistrationLightStepOtpPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.flagStep,
            element: <RegistrationLightStepFlag />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
        ],
      },
      {
        path: RoutePath.registration,
        element: <RegistrationMerchantLandingPage />,
        handle: {
          isResponsive: true,
          hasNavBar: false,
        },
      },
      {
        path: RoutePath.registrationCollaboratorLandingPage,
        element: <RegistrationCollaboratorLandingPage />,
        handle: {
          isResponsive: true,
          hasNavBar: false,
        },
      },
      {
        path: RoutePath.registrationCollaborator,
        element: <RegistrationCollaborator />,
        handle: {
          isResponsive: true,
          hasNavBar: false,
        },
        children: [
          {
            path: RoutePath.phoneStep,
            element: <RegistrationCollaboratorStepPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.otpConfirmPhoneStep,
            element: <RegistrationCollaboratorStepOtpPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.passwordStep,
            element: <RegistrationCollaboratorStepPassword />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.flagStep,
            element: <RegistrationCollaboratorStepFlag />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
        ],
      },
      {
        path: RoutePath.registrationMerchant,
        element: <RegistrationMerchantLoadable />,
        handle: {
          isResponsive: true,
          hasNavBar: false,
        },
        children: [
          {
            path: RoutePath.stepCountry,
            element: <RegistrationStepCountry />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.stepVAT,
            element: <RegistrationStepVAT />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.stepNamePostalCode,
            element: <RegistrationStepNamePostalCode />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.stepName,
            element: <RegistrationStepName />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.chooseCompany,
            element: <RegistrationChooseCompany />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.fiscalCodeStep,
            element: <RegistrationStepCf />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.checkDataStep,
            element: <RegistrationStepCheckData />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.emailStep,
            element: <RegistrationStepEmail />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.otpConfirmEmailStep,
            element: <RegistrationStepOtpEmail />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.phoneStep,
            element: <RegistrationStepPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.otpConfirmPhoneStep,
            element: <RegistrationStepOtpPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.passwordStep,
            element: <RegistrationStepPassword />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.flagStep,
            element: <RegistrationStepFlag />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
        ],
      },
      {
        path: RoutePath.pluginInfo,
        element: <PluginInfo />,
        handle: {
          hasNavBar: false,
          hasHeaderPlugin: true,
          isResponsive: true,
        },
      },
      {
        path: RoutePath.ecommerce,
        element: <Ecommerce />,
        handle: {
          hasNavBar: false,
          hasHeaderPlugin: true,
          isResponsive: true,
        },
        children: [
          {
            path: RoutePath.ecommerceCheckData,
            element: <EcommerceCheckData />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
            },
          },
          {
            path: RoutePath.ecommerceCheckout,
            element: <ECommerceCheckout />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
            },
          },
          {
            path: RoutePath.paymentSuccessPlugin,
            element: <PluginPaymentSuccessPage />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
            },
          },
          {
            path: RoutePath.setupSuccessPlugin,
            element: <PluginSetupSuccessPage />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
            },
          },
        ],
      },
      {
        path: RoutePath.registrationGuest,
        element: <RegistrationGuestLoadable />,
        children: [
          {
            path: RoutePath.phoneStep,
            element: <RegistrationGuestStepPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.otpConfirmPhoneStep,
            element: <RegistrationGuestStepOtpPhone />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.checkDataStep,
            element: <RegistrationGuestStepCheckData />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.emailStep,
            element: <RegistrationGuestStepEmail />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.otpConfirmEmailStep,
            element: <RegistrationGuestStepOtpEmail />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.passwordStep,
            element: <RegistrationGuestStepPassword />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.flagStep,
            element: <RegistrationGuestStepFlag />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.successRegistration,
            element: <RegistrationGuestSuccess />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
              hasFooter: false,
            },
          },
        ],
      },
      {
        path: RoutePath.recoveryData,
        element: <RecoveryData />,
        children: [
          {
            path: RoutePath.recoveryEmail,
            element: <RecoveryEmail />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.recoveryPassword,
            element: <RecoveryPassword />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.insertOtp,
            element: <InsertOtp />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.performOtp,
            element: <ConfirmOtp />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.showEmail,
            element: <ShowEmail />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.insertPassword,
            element: <InsertPassword />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
        ],
      },
      {
        path: RoutePath.purchase,
        element: <PurchaseFlow />,
        handle: { isResponsive: true, hasFooter: false },
      },
      {
        path: RoutePath.splat,
        element: <Navigate to={RoutePath.oops} />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.oops,
        element: <ErrorPage />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.oopsPlugin,
        element: <ErrorPage />,
        handle: {
          hasNavBar: false,
          hasHeaderPlugin: true,
          isResponsive: true,
        },
      },
      {
        path: RoutePath.ThankyouPage,
        element: <ThankYouPage />,
        children: [
          {
            path: RoutePath.successRegistration,
            element: <SuccessRegistration />,
          },
          {
            path: RoutePath.successChangePassword,
            element: <SuccessChangePassword />,
          },
          {
            path: RoutePath.successRecoveryPassword,
            element: <SuccessRecoveryPassword />,
          },
          {
            path: RoutePath.successChangeNumber,
            element: <SuccessChangeNumber />,
          },
          {
            path: RoutePath.successChangeEmail,
            element: <SuccessChangeEmail />,
          },
        ],
      },
      {
        path: RoutePath.welcomePage,
        element: <WelcomePage />,
        handle: { isResponsive: true, hasfooter: 'onlyDesktop' },
      },
      {
        path: RoutePath.isYourCompany,
        element: <IsYourCompany />,
        handle: { isResponsive: true, hasFooter: false },
      },
      {
        path: RoutePath.ThankyouPageError,
        element: <FailedPage />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
        children: [
          {
            path: RoutePath.alreadyPaidPlugin,
            element: <AlreadyPaidPlugin />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
            },
          },
          {
            path: RoutePath.createLinkError,
            element: <CreateLinkError />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.ecommerceWrongData,
            element: <EcommerceWrongData />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
            },
          },
          {
            path: RoutePath.expiredLink,
            element: <ExpiredLink />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.canceledLink,
            element: <CanceledLink />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.loginBuyerError,
            element: <LoginBuyerError />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.uwBuyerError,
            element: <UWBuyerError />,
            handle: {
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.uwBuyerErrorPlugin,
            element: <UWBuyerError />,
            handle: {
              isResponsive: true,
              hasHeaderPlugin: true,
            },
          },
          {
            path: RoutePath.paymentError,
            element: <PaymentError />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.linkAlreadyPaid,
            element: <LinkAlreadyPaid />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.notMyCompany,
            element: <NotMyCompany />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.fiscalCodeMatchError,
            element: <FiscalCodeMatchError />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.buyerFiscalCodeError,
            element: <BuyerFiscalCodeError />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.internalError,
            element: <InternalError />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.signGenericError,
            element: <SignGenericError />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.wrongUserError,
            element: <SignWrongUser />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.paymentFailed,
            element: <PaymentFailed />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.expiredCart,
            element: <ExpiredCart />,
            handle: {
              isResponsive: true,
              hasHeaderPlugin: true,
            },
          },
        ],
      },
      {
        path: RoutePath.loadingNamirial,
        element: <LoadingNamirial />,
      },
    ],
  },
  // {
  //   path: '*',
  //   element: <Navigate to={'/it'} />,
  //   id: 'PUBLIC',
  //   children: []
  // },
  {
    element: <ProtectedRoute />,
    id: 'PRIVATE',
    children: [
      {
        path: RoutePath.createLinkExit,
        element: <PrivateArea />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.privateArea,
        element: <PrivateArea />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.advancePaymentInfo,
        element: <AdvancePaymentInfo />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.remodulationInfo,
        element: <RemodulationInfo />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.welcomePageLogged,
        element: <WelcomePage logged />,
        handle: { isResponsive: true, hasFooter: 'onlyDesktop' },
      },
      {
        path: RoutePath.purchaseLogged,
        element: <PurchaseFlow logged />,
        handle: { isResponsive: true, hasFooter: false },
      },
      {
        path: RoutePath.remodulationRequests,
        element: <RemodulationRequests />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.advancePlanRequests,
        element: <AdvancePlanRequests />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.monitoring,
        element: <Monitoring />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.monitoringDetail + ':guid/',
        element: <MonitoringBuyerDetail />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.createLink,
        element: <CreateLinkFlow />,
        handle: {
          isResponsive: true,
        },
        children: [
          {
            path: RoutePath.chooseCategory,
            element: <ChooseCategory />,
            handle: {
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.chooseProduct,
            element: <ChooseProduct />,
            handle: {
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: ':categoryCode/:productCode/',
            children: [
              {
                path: RoutePath.stepCustomer,
                element: <StepCustomer />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.chooseCompany,
                element: <ChooseCompany />,
                handle: {
                  isResponsive: true,
                  hasFooter: false,
                },
              },
              {
                path: RoutePath.stepCountry,
                element: <StepCountry />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepNamePostalCode,
                element: <StepNamePostalCode />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepName,
                element: <StepName />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepVAT,
                element: <StepVAT />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepCf,
                element: <StepCf />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepCheckData,
                element: <StepCheckData />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepImport,
                element: <StepImport />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepDescription,
                element: <StepDescription />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepInstallmentPlan,
                element: <StepInstallmentPlan />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.startInstallmentPlan,
                element: <StartInstallmentPlan />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.dateInstallmentPlan,
                element: <DateInstallmentPlan />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepInstallmentDelay,
                element: <StepInstallmentDelay />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepInstallmentNumber,
                element: <StepInstallmentNumber />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepSelectTemplates,
                element: <StepSelectTemplates />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepInstallmentAmount,
                element: <StepInstallmentAmount />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepCustomInstallmentForm,
                element: <StepCustomInstallmentForm />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepEmail,
                element: <StepEmail />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepResume,
                element: <StepResume />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepCreditResume,
                element: <StepCreditResume />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepCollectionMethod,
                element: <StepCollectionMethod />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepInvoiceDate,
                element: <StepInvoiceDate />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepCustomerValidation,
                element: <StepCustomerValidation />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.stepSendLink,
                element: <StepSendLink />,
                handle: {
                  hasNavBar: false,
                  hasFooter: false,
                  isResponsive: true,
                },
              },
              {
                path: RoutePath.showQrCode,
                element: <ShowQrCode />,
                handle: { hasFooter: false, isResponsive: true },
              },
              {
                path: RoutePath.sendLinkNow,
                element: <SendLinkNow />,
                handle: { hasFooter: false, isResponsive: true },
              },
              {
                path: RoutePath.successSendLink,
                element: <SuccessSendLink />,
                handle: { hasFooter: false, isResponsive: true },
              },
            ],
          },
        ],
      },
      {
        path: RoutePath.advancePaymentRequest,
        element: <ADVPaymentFlow />,
        handle: {
          isResponsive: true,
        },
        children: [
          {
            path: RoutePath.chooseLink,
            element: <ChooseLinkPage />,
            handle: {
              isResponsive: true,
              hasNavBar: true,
            },
          },
          {
            path: RoutePath.linkDetail,
            element: <LinkDetail />,
            handle: {
              isResponsive: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.requestsResults,
            element: <RequestsResults />,
            handle: {
              isResponsive: true,
            },
          },
        ],
      },
      {
        path: RoutePath.AdvPayResultPage,
        element: <ADVPayResultPage />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.linkDiPagamento,
        element: <PayByLinkPage madeFromPlugin={false} />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.linkOnline,
        element: <PayByLinkPage madeFromPlugin />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.balanceMovements,
        element: <BalanceMovementsPage />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.accessData,
        element: <AccessData />,
      },
      {
        path: RoutePath.access,
        element: <AccessData />,
      },
      {
        path: RoutePath.changeAccessData,
        element: <ChangeAccessData />,
        children: [
          {
            path: RoutePath.authOtp,
            element: <OtpAuth />,
          },
          {
            path: RoutePath.performOtp,
            element: <OtpPerform />,
          },
          {
            path: RoutePath.changeEmail,
            element: <ChangeEmail />,
          },
          {
            path: RoutePath.changePhone,
            element: <ChangePhone />,
          },
          {
            path: RoutePath.changePassword,
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: RoutePath.financialTransaction,
        element: <RedirectStripe />,
      },
      {
        path: RoutePath.fundsTransfer,
        element: <RedirectStripe />,
      },
      {
        path: RoutePath.documents,
        element: <DocumentsPage />,
      },
      {
        path: RoutePath.personalData,
        element: <PersonalData />,
      },
      {
        path: RoutePath.paymentSummary,
        element: <PaymentSummary />,
      },
      {
        path: RoutePath.account,
        element: <Account />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.usageSetting,
        element: <UsageSetting />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.usageSettingOptions,
        element: <UsageSettingOptions />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.usageSettingSavedTemplates,
        element: <UsageSettingSavedTemplates />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.usageSettingTemplate + ':productTemplateId',
        element: <UsageSettingTemplate />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.usageSettingOptionsLiquidity,
        element: <UsageSettingLiquidity />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.usageSettingOptionsRequirements,
        element: <UsageRequirements />,
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.support,
        element: <Support />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path:
          RoutePath.linkDiPagamentoDetail + RoutePath.actionsSuccessCanceled,
        element: <PayByLinkSuccessCanceled madeFromPlugin={false} />,
        handle: {
          hasHeader: false,
        },
      },
      {
        path: RoutePath.linkOnlineDetail + RoutePath.actionsSuccessCanceled,
        element: <PayByLinkSuccessCanceled madeFromPlugin />,
        handle: {
          hasHeader: false,
        },
      },
      {
        path: RoutePath.linkDiPagamentoDetail + ':idlink/',
        element: <PayByLinkDetailPage madeFromPlugin={false} />,
        children: [
          {
            path: RoutePath.help,
            element: <PayByLinkDetailHelp />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.beforeEditPlan,
            element: <BeforeEditInstallmentPlan />,
            handle: {
              hasHeader: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.successRemodulation,
            element: <PayByLinkSuccessRemodulation />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.info,
            element: <PayByLinkDetailInfo />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.actionsResendLink,
            element: <PayByLinkActionsResendLink />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsSuccessResend,
            element: <PayByLinkActionsSuccessResend />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsLinkChoice,
            element: <PayByLinkActions />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsShowQrcode,
            element: <PayByLinkActionsQR />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsSendLinkNow,
            element: <PayByLinkActionsSendLinkNow />,
            handle: {
              hasHeader: false,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.actionsSupport,
            element: <PayByLinkActionsSupport />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsSuccessCheckCreditWorthiness,
            element: <PayByLinkActionsResultEvaluationBuyer />,
            handle: {
              hasFooter: 'onlyDesktop',
              isResponsive: true,
            },
          },
        ],
      },
      {
        path: RoutePath.linkOnlineDetail + ':idlink/',
        element: <PayByLinkDetailPage madeFromPlugin />,
        children: [
          {
            path: RoutePath.help,
            element: <PayByLinkDetailHelp />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.beforeEditPlan,
            element: <BeforeEditInstallmentPlan />,
            handle: {
              hasHeader: true,
              hasNavBar: false,
            },
          },
          {
            path: RoutePath.successRemodulation,
            element: <PayByLinkSuccessRemodulation />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.info,
            element: <PayByLinkDetailInfo />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.actionsResendLink,
            element: <PayByLinkActionsResendLink />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsSuccessResend,
            element: <PayByLinkActionsSuccessResend />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsLinkChoice,
            element: <PayByLinkActions />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsShowQrcode,
            element: <PayByLinkActionsQR />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsSendLinkNow,
            element: <PayByLinkActionsSendLinkNow />,
            handle: {
              hasHeader: false,
            },
          },
          {
            path: RoutePath.actionsSupport,
            element: <PayByLinkActionsSupport />,
            handle: {
              hasHeader: false,
            },
          },
        ],
      },
      {
        path: RoutePath.saveTemplate,
        element: <SaveTemplate />,
        handle: {
          hasNavBar: false,
          isResponsive: true,
        },
      },
      {
        path: RoutePath.templateSaved,
        element: (
          <LandingPage
            icon={ImgFileSaved}
            title="text.savedTemplateTitle"
            content="text.savedTemplateContent"
            buttonLabel="lbl.showAllPayByLink"
            buttonClickPath={RoutePath.linkDiPagamento}
          />
        ),
        handle: { isResponsive: true },
      },
      {
        path: RoutePath.marketingChoice,
        element: <MarketingChoice />,
      },
      {
        path: RoutePath.paymentFlow,
        element: <PaymentFlowPage />,
        handle: {
          hasNavBar: false,
          isResponsive: true,
          hasFooter: false,
        },
        children: [
          {
            path: RoutePath.setMethods,
            element: <SetMethodPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.setBackup,
            element: <SetBackupPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.chooseMethod,
            element: <ChoosePaymentMethodPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.payWithOpyn,
            element: <PayWithOpynPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.paymentSuccess,
            element: <PaymentSuccessPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.setupSuccess,
            element: <SetupSuccessPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.multiPaymentSuccess,
            element: <MultiPaymentSuccessPage />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: 'onlyDesktop',
            },
          },
          {
            path: RoutePath.setupSuccessPlugin,
            element: <SetupSuccessPage />,
            handle: {
              hasNavBar: false,
              hasHeaderPlugin: true,
              isResponsive: true,
              hasFooter: false,
            },
          },
          {
            path: RoutePath.payment,
            element: <PaymentCard />,
            handle: {
              hasHeader: false,
              hasFooter: false,
            },
          },
        ],
      },
      {
        path: RoutePath.continueRegistration,
        element: <ContinueRegistration />,
        handle: {
          isResponsive: true,
          hasFooter: false,
        },
      },
      {
        path: RoutePath.collaboratorInfo,
        element: <CollaboratorsInfoPage />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.addCollaborator,
        element: <AddCollaborator />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.installments,
        element: <Installments />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.methods,
        element: <PaymentMethodsFlow />,
        handle: { isResponsive: true },
        children: [
          {
            path: RoutePath.paymentMethods,
            element: <PaymentMethodsPage />,
            handle: { isResponsive: true },
          },
          {
            path: RoutePath.addMethodStripe,
            element: <AddMethodStripePage />,
          },
          {
            path: RoutePath.addBackupMethod,
            element: <AddBackupMethodPage />,
            handle: { isResponsive: true },
          },
          {
            path: RoutePath.chooseMethod,
            element: <ChooseMethodPage />,
            handle: { isResponsive: true },
          },
          {
            path: RoutePath.setBackup,
            element: <SetBackupMethodPage />,
            handle: { isResponsive: true },
          },
        ],
      },
      {
        path: RoutePath.orders,
        element: <Orders />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.orderDetail + ':orderGuid/',
        element: <OrderDetail />,
        handle: {
          isResponsive: true,
        },
        children: [
          {
            path: RoutePath.info,
            element: <OrderDetailInfo />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.help,
            element: <OrderDetailHelp />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.selectExpiredInstallments,
            element: <MultiplePaymentPage selectableStatus={3} />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
            },
          },
          {
            path: RoutePath.selectInstallments,
            element: <MultiplePaymentPage selectableStatus={0} />,
            handle: {
              hasNavBar: false,
              isResponsive: true,
              hasFooter: false,
            },
          },
        ],
      },
      {
        path: RoutePath.collaborators,
        element: <CollaboratorsPage />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.successAddCollaborator,
        element: <SuccessAddCollaborator />,
        handle: {
          isResponsive: true,
          hasFooter: 'onlyDesktop',
        },
      },
      {
        path: RoutePath.successRegistrationCollaborator,
        element: <RegistrationCollaboratorSuccessPage />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.collaborator,
        element: <Collaborator />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.ecommerceInfo,
        element: <EcommerceInfo />,
        handle: {
          isResponsive: true,
        },
      },
      {
        path: RoutePath.onboarding,
        element: <OnboardingFlow />,
        handle: {
          isResponsive: true,
        },
        children: [
          {
            path: RoutePath.readContract,
            element: <ReadContract />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.signWithSpid,
            element: <SignWithSpid />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.signStripe,
            element: <SignStripe />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.successNamirial,
            element: <SuccessNamirial />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.errorKoAdmin,
            element: <ErrorKoAdmin />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.assistance,
            element: <Assistance />,
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.uploadedContract,
            element: (
              <LandingPage
                icon={UploadedContractImage}
                title="text.successUploadedContract"
                buttonLabel="lbl.reviewTheContract"
                buttonClickPath={RoutePath.onboarding + RoutePath.readContract}
              />
            ),
            handle: {
              isResponsive: true,
            },
          },
          {
            path: RoutePath.success,
            element: <SuccessStripe />,
            handle: {
              isResponsive: true,
            },
          },
        ],
      },
    ],
  },
];

const devOnlyTestPage: RouteObject = {
  path: RoutePath.devOnlyTestPage,
  element: <DevOnlyTestPage />,
  handle: {
    isResponsive: true,
  },
};

if (process.env.NODE_ENV !== 'production') {
  routerConfig[0].children?.push(devOnlyTestPage);
}

export default routerConfig;
