import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';
import Icon from 'src/components/Icon/Icon.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ArrowRight } from 'src/images/icons/arrow_right.svg';
import { LinkAccount } from 'src/pages/account/style/LinkAccount.style';
import { RoutePath } from 'src/routers/routers.config';
import { Hr } from 'src/style-utils/hr';
import { P } from 'src/style-utils/typographic';
import { UsageSettingArray } from './usageSetting.config';

const UsageSetting: React.FC = () => {
  return (
    <div className="container">
      <GoBackButton url={RoutePath.account} label="text.profile" />
      <div className="mx-auto max-w-md">
        <TextDefaultPage title="text.usageSetting" />
        {UsageSettingArray.map((card, index) => (
          <div key={index}>
            <LinkAccount to={card.path}>
              <P>
                <Translate id={card.text} />
              </P>
              <Icon iconSize={'2.8rem'} svgIconComponent={ArrowRight} />
            </LinkAccount>
            {card.divider && <Hr />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UsageSetting;
