import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import NeedHelpButton from 'src/components/NeedHelpButton/NeedHelpButton.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as successRemodulation } from 'src/images/icons/success-remodulation.svg';
import { ResetPayByLinkStore } from 'src/pages/createLinkFlow/CreateLinkFlow.config';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import { ContainerPadding } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import styled from 'styled-components';

const Svg = styled(successRemodulation)`
  &[data-wait='true'] > #checkmark {
    opacity: 0;
  }
  width: 38.4rem;
  height: auto;
`;

function PayByLinkSuccessRemodulation() {
  const location = useLocation();
  const approvation = location?.state?.approvation;
  const { idLink } = useParams();
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);
  const setValues = usePayByLinkStore((state) => state.setValue);
  useEffect(() => {
    setValue('stateFlow', false);
    setValues(ResetPayByLinkStore);
  }, []);
  return (
    <ContainerPadding>
      <Flex margin="4.2rem auto" justify="center">
        <Svg data-wait={approvation} />
      </Flex>
      <Flex
        flexDirection="column"
        alignContent="center"
        style={{ textAlign: 'center' }}
      >
        <TextDefaultPage
          title={
            approvation
              ? 'text.successWaitRemodulation'
              : 'text.successRemodulation'
          }
          subTitle={
            approvation
              ? 'text.successWaitRemodulationSubtitle'
              : 'text.successRemodulationSubtitle'
          }
        />
      </Flex>
      <ButtonContainer>
        <Button
          onClick={() =>
            navigate(
              approvation
                ? RoutePath.remodulationRequests
                : `${RoutePath.linkDiPagamentoDetail}${idLink}/${RoutePath.info}`,
            )
          }
          variant="Primary"
        >
          <Translate
            id={approvation ? 'lbl.goToRequests' : 'lbl.showLinkDetail'}
          />
        </Button>
        {approvation && <NeedHelpButton />}
      </ButtonContainer>
    </ContainerPadding>
  );
}

export default PayByLinkSuccessRemodulation;
