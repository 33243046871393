export const modalTheme = {
  modal: {
    content: {
      mobile: {
        top: '',
        bottom: '0',
        left: '',
        right: '',
        maxWidth: '100%',
        padding: '2.4rem',
        borderRadius: '1.2rem',
        paddingTop: '0rem',
        maxHeight: '95%',
      },
      tablet: {
        top: '',
        bottom: '0',
        left: '',
        right: '',
        maxWidth: '77.6rem',
        padding: '4rem',
        borderRadius: '1.2rem',
        paddingTop: '0rem',
        maxHeight: '95%',
      },
      desktop: {
        top: '5%',
        bottom: 'unset',
        left: '',
        right: '',
        maxWidth: '77.6rem',
        padding: '4rem',
        borderRadius: '1rem',
        paddingTop: '0rem',
        maxHeight: '95%',
      },
    },
  },
};
