import { Button } from 'src/components/Button/Button.component';
import DoubleChoicePopUp from 'src/components/DoubleChoicePopUp/DoubleChoicePopUp.component';
import {
  flexAmount,
  flexDate,
} from 'src/components/FlexInstallmentCard/FlexInstallmentCard.config';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import TotalInstallmentForm from 'src/components/TotalInstallmentForm/TotalInstallmentForm.component';
import Translate from 'src/components/Translate/Translate.component';
import TriggerForm from 'src/components/TriggerForm/TriggerForm.component';
import VerticalStepperRow from 'src/components/VerticalStepperRow/VerticalStepperRow.component';
import { ReactComponent as AlertIcon } from 'src/images/icons/alert_orange_tiny.svg';
import { useRedirectToFlow } from 'src/pages/createLinkFlow/CreateLinkFlow.hooks';

import moment from 'moment';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePayByLinkStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import {
  findMinMax,
  formConfigFlexInstallmentPayByLink,
} from './StepCustomInstallmentForm.helpers';
import {
  ButtonContainerCustom,
  ResponsiveContainerCustom,
} from './StepCustomInstallmentForm.style';

function StepCustomInstallmentForm() {
  const { urlRedirectToFlow, step, stepMax } = useRedirectToFlow();
  const [modalBackOpen, setModalBackOpen] = useState(false);
  const [modalResetOpen, setModalResetOpen] = useState(false);
  const handleClose = () => {
    setModalBackOpen(false);
    setModalResetOpen(false);
  };

  const resetForm = async () => {
    setModalResetOpen(false);
    setValue('customInstallments', {});
    navigate(0);
    methods.reset();
  };

  const goBackAndReset = async () => {
    setModalBackOpen(false);
    setValue('customInstallments', {});
    navigate(-1);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [residualAmount, setResidualAmount] = useState(0);

  const { amount, setValue, customInstallments, remodulation } =
    usePayByLinkStore((state) => state);

  const NumRate = parseInt(location?.state?.NumRate, 10);
  const { initialValues, resolver } = formConfigFlexInstallmentPayByLink(
    NumRate,
    customInstallments?.Installments,
    remodulation,
  );
  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });
  const { errors, dirtyFields, isValid, touchedFields } = methods.formState;

  const onSubmit = (data: any) => {
    const Installments: {
      Expiration: string;
      Amount: number;
      Status: number;
    }[] = [];
    [...Array(NumRate)].map((item, index) => {
      Installments.push({
        Expiration: moment(data[`${flexDate}-${index}`], 'YYYY-MM-DD')
          .hour(12)
          .toISOString(),
        Amount: data[`${flexAmount}-${index}`],
        Status: 0,
      });
    });
    const { min, max } = findMinMax(Installments);
    const customInstallments = {
      Code: 'BNPL-CUSTOM-RATE',
      FlagCessioneCredito: 0,
      NumRate,
      Installments,
      InstallmentMinAmount: min,
      InstallmentMaxAmount: max,
    };
    setValue('customInstallments', customInstallments);
    navigate(urlRedirectToFlow);
  };

  const flexCards: any[] = [];

  const [focused, setFocused] = useState(0);

  for (let i = 0; i < NumRate; i++) {
    const nameAmount = `${flexAmount}-${i}`;
    const nameDate = `${flexDate}-${i}`;
    const dirty = dirtyFields[nameAmount] && dirtyFields[nameDate];
    const error = errors[nameAmount] || errors[nameDate];

    flexCards.push({
      valid: dirty && !error,
      disabled:
        !customInstallments?.Installments &&
        i > 0 &&
        !dirty &&
        !flexCards[i - 1].valid,
      errorMessageDate: touchedFields[nameDate] && errors[nameDate],
      errorMessageAmount: touchedFields[nameAmount] && errors[nameAmount],
    });
  }

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={step}
        stepMax={stepMax}
        title="lbl.amountAndDateInstallments"
        alertBack={() => setModalBackOpen(true)}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <FlowContainer>
            {customInstallments?.TemplateName ? (
              <P>{customInstallments?.TemplateName}</P>
            ) : (
              <H4>
                <Translate id="text.insertDateandAmountInstallments" />
              </H4>
            )}
            <div>
              {flexCards.map((flexCardProps, index: number) => {
                return (
                  <VerticalStepperRow
                    key={index}
                    index={index}
                    focused={focused === index}
                    numRate={NumRate}
                    {...flexCardProps}
                    onClickHandler={() =>
                      setFocused(flexCardProps.disabled ? focused : index)
                    }
                  />
                );
              })}
            </div>
            <TriggerForm />
            <div
              style={{
                textAlign: 'center',
                zIndex: '0',
                marginBottom: '16rem',
              }}
            >
              <Button
                type="button"
                variant="LinkPrimary"
                i18n
                underline
                padding="0"
                onClick={() => {
                  setModalResetOpen(true);
                }}
                id={IDs.btnClean}
                translatedText="lbl.resetFlexForm"
              />
            </div>
          </FlowContainer>
          <ButtonContainerCustom>
            <ResponsiveContainerCustom>
              <TotalInstallmentForm
                NumRate={NumRate}
                setResidualAmount={setResidualAmount}
                ImportoOrdine={amount}
                residualAmount={Math.round(residualAmount * 100) / 100}
              />
              <SubmitButton
                disabled={
                  !isValid || Math.round(residualAmount * 100) / 100 !== 0
                }
                minWidth="28rem"
                id={IDs.btnProceed}
              >
                <Translate id="lbl.next" />
              </SubmitButton>
            </ResponsiveContainerCustom>
          </ButtonContainerCustom>
        </form>
      </FormProvider>
      {modalBackOpen && (
        <DoubleChoicePopUp
          handleClose={handleClose}
          handleSecondAction={goBackAndReset}
          firstButtonLabel="lbl.yesGoBack"
          lastButtonLabel="lbl.close"
          mainText="text.sureBackSetInstallments"
          icon={AlertIcon}
        />
      )}
      {modalResetOpen && (
        <DoubleChoicePopUp
          handleClose={handleClose}
          handleSecondAction={resetForm}
          firstButtonLabel="lbl.confirmCleanData"
          lastButtonLabel="lbl.cancel"
          mainText="text.confirmCleanData"
          icon={AlertIcon}
        />
      )}
    </>
  );
}

export default StepCustomInstallmentForm;
