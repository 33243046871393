import { Button } from 'src/components/Button/Button.component';
import Icon from 'src/components/Icon/Icon.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as BinIcon } from 'src/images/icons/bin.svg';
import { ReactComponent as IconSelected } from 'src/images/icons/checkmark.svg';
import { ReactComponent as Plus } from 'src/images/icons/circled_plus.svg';
import { ReactComponent as EditPencil } from 'src/images/icons/editPencil.svg';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { EligibleOrder } from 'src/services/orderLink/orderLink.types';
import { H5, P, PSmall } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import {
  CardLinkContainer,
  CardLinkGrid,
  CustomButton,
  CustomIcon,
} from './ChooseLinkPage.style';
import { Flex } from 'src/style/flexbox.style';
import colors from 'src/style-utils/colors';
import { ReactComponent as CheckIcon } from 'src/images/icons/circular_checkmark_green.svg';
import { ReactComponent as NegativeIcon } from 'src/images/icons/negative feedback_point_red.svg';
import { ReactComponent as CalendarIcon } from 'src/images/icons/calendar.svg';

const ChooseLinkCard: React.FC<{
  link: EligibleOrder;
  selectedLink: (link: EligibleOrder) => void;
  removedLink: (orderId: number) => void;
  editInvoiceDate: any;
  selected: boolean;
}> = ({ link, selectedLink, removedLink, selected, editInvoiceDate }) => {
  const {
    formatters: { formatCurrency },
  } = useI18n();
  const infos = [
    {
      label: 'lbl.linkCreationDate',
      content: refactorFormatDate(link.OrderCreationDate),
    },
    { label: 'lbl.advPayCardGrid1', content: formatCurrency(link.OrderAmount) },
    {
      label: 'lbl.lastEvaluation',
      content: (
        <>
          <Flex gap="2.4rem">
            <Flex align="center" gap="4px">
              <Icon
                iconSize="20px"
                color={colors.textP}
                svgIconComponent={
                  link.Underwriting === 'OK' ? CheckIcon : NegativeIcon
                }
              />
              <P bold>
                <Translate
                  id={link.Underwriting === 'OK' ? 'lbl.UwOk' : 'lbl.UwKo'}
                />
              </P>
            </Flex>
            <Flex align="center" gap="4px">
              <Icon
                iconSize="20px"
                color={colors.textP}
                svgIconComponent={CalendarIcon}
              />
              <P bold>{refactorFormatDate(link.UnderwritingDate)}</P>
            </Flex>
          </Flex>
        </>
      ),
    },
  ];
  const buttonLabel = selected ? 'lbl.removeLink' : 'lbl.selectLink';

  const buttonAction = selected
    ? () => removedLink(link.OrderId)
    : () => selectedLink(link);

  return (
    <CardLinkContainer data-selected={selected}>
      {selected && (
        <CustomIcon svgIconComponent={IconSelected} iconSize="3.2rem" />
      )}
      <P>
        <Translate id="lbl.linkNumber" />
        :&nbsp;#
        {link.OrderId.toString().padStart(5, '0')}
      </P>
      <H5
        className="line-clamp-2"
        style={{ fontSize: '2.4rem', lineHeight: '3.6rem' }}
      >
        {link.BuyerName}
      </H5>
      <hr style={{ width: '100%', marginTop: '16px', marginBottom: '16px' }} />
      <P>
        <Translate id="lbl.advPayImport" />
      </P>
      <H5 style={{ fontSize: '2.4rem', lineHeight: '3.6rem' }}>
        {formatCurrency(link.AmountGivable)}
      </H5>

      <CardLinkGrid>
        {infos.map((item, i) => (
          <div key={i}>
            <PSmall>
              <Translate id={item.label} />
            </PSmall>
            <P bold>{item.content}</P>
          </div>
        ))}
      </CardLinkGrid>

      {link.InvoiceDate && (
        <Button
          rightChild={
            link.InvoiceDateEditable && selected ? (
              <Icon svgIconComponent={EditPencil} />
            ) : undefined
          }
          padding="0"
          margin="0"
          disabled={!link.InvoiceDateEditable && !selected}
          variant="LinkPrimary"
          underline={link.InvoiceDateEditable && selected}
          onClick={() =>
            link.InvoiceDateEditable && selected ? editInvoiceDate(link) : ''
          }
        >
          <P
            colorBlack={link.InvoiceDateEditable && selected}
            textAlign="center"
            marginBottom="2.4rem"
            marginTop="2.4rem"
          >
            <Translate id="lbl.invoiceDate2" />
            <strong> {refactorFormatDate(link.InvoiceDate)}</strong>
          </P>
        </Button>
      )}
      <CustomButton
        minWidth="100%"
        onClick={buttonAction}
        variant="Quaternary"
        data-selected={selected}
        leftChild={<Icon svgIconComponent={selected ? BinIcon : Plus} />}
      >
        &nbsp;
        <Translate id={buttonLabel} />
      </CustomButton>
    </CardLinkContainer>
  );
};

export default ChooseLinkCard;
