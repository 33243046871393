import DevOnlyDashBoard from 'src/components/DevOnlyDashboard/DevOnlyDashboard.loadable';
import Footer from 'src/components/Footer/Footer.component';
import Header from 'src/components/Header/Header.component';
import { useHandle } from 'src/components/Header/Header.hooks';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useScrollToTopOnPathnameChange } from 'src/routers/routers.hooks';
import { Container, ContainerPage } from 'src/style/Container.style';
import { useCheckErrorPageLoggedIn } from './MainLayout.hooks';

export const MainLayout: React.FC = () => {
  const shouldLoadDevTools = process.env.NODE_ENV !== 'production';
  useScrollToTopOnPathnameChange();
  const isResponsive = useHandle().isResponsive;
  const hasFooter = useHandle().hasFooter;
  const isLoggedIn = useCheckErrorPageLoggedIn();

  return (
    <>
      <Header loggedIn={isLoggedIn} />
      {isResponsive ? (
        <ContainerPage>
          <Outlet />
        </ContainerPage>
      ) : (
        <Container>
          {shouldLoadDevTools && <DevOnlyDashBoard />}
          <Outlet />
        </Container>
      )}
      {hasFooter === 'onlyDesktop' ? (
        <Footer onlyDesktop={true} />
      ) : hasFooter ? (
        <Footer />
      ) : null}
    </>
  );
};
