import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import InputDate from 'src/components/InputDate/InputDate.component';
import InputField from 'src/components/InputField/InputField.component';
import Translate from 'src/components/Translate/Translate.component';
import { FormProvider, useForm } from 'react-hook-form';
import { Hr } from 'src/style-utils/hr';
import { P, PSmall } from 'src/style-utils/typographic';
import { Flex } from 'src/style/flexbox.style';
import CheckboxStripe from '../CheckboxStripe/CheckboxStripe.component';
import { useIsAtLeastTabletBreakpoint } from 'src/utils/hooks/media-queries';
import Loader from 'src/components/Loader/Loader.component';
import { useOutletContext } from 'react-router-dom';
import { EcommerceContext } from '../../../../Ecommerce.component';
import { t } from 'i18next';

const FakeCheckoutForm = ({ isLoading }: { isLoading?: boolean }) => {
  const isTablet = useIsAtLeastTabletBreakpoint();
  const methods = useForm();
  const LenderName =
    useOutletContext<EcommerceContext>().sessionData?.LenderName;
  if (isLoading) {
    return (
      <div style={{ position: 'relative', minHeight: '30rem' }}>
        <Loader overlayViewMode="content" active viewMode="absolute">
          <P>
            <Translate id="text.loaderEcommerceCheckout" />
          </P>
          <P bold colorBlack>
            <Translate id="text.dontClosePage" />
          </P>
        </Loader>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2.4rem' }}>
      <FormProvider {...methods}>
        <form>
          <PSmall colorBlack>
            <Translate id="lbl.cardNumber" />
          </PSmall>
          <InputField disabled name="ok" placeholder="1234 1234 1234 1234" />
          <Flex marginTop="2.1rem" gap="2.4rem" fluid>
            <div style={{ flex: 1 }}>
              <PSmall colorBlack>
                <Translate id="text.dueDate" />
              </PSmall>
              <InputDate name="ok" disabled />
            </div>
            <div style={{ flex: 1 }}>
              <PSmall colorBlack>
                <Translate id="lbl.cvc" />
              </PSmall>
              <InputField disabled name="ok" placeholder="CVC" />
            </div>
          </Flex>
          <PSmall colorBlack>
            <Translate id="lbl.country" />
          </PSmall>
          <InputField disabled name="ok" placeholder="Italia" />
          <Hr />
          <CheckboxStripe
            description={t('text.okCreditAssignment', { x: LenderName })}
            checked={false}
            disabled
          />
          {isTablet && <Hr />}
          <ButtonContainer>
            <Button type={'submit'} minWidth="100%" variant="Primary" disabled>
              <Translate id="lbl.payNowAlt" />
            </Button>
          </ButtonContainer>
        </form>
      </FormProvider>
    </div>
  );
};

export default FakeCheckoutForm;
