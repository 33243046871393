import React from 'react';
import { IconRoot, IconRootProps } from './style/Icon.style';

export type IconProps = React.SVGProps<SVGSVGElement> &
  IconRootProps & {
    svgIconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    onClick?: () => void;
  };

/**
 * Displays SVG images.
 *
 * 2 api:
 *
 * 1. Prop 'iconSrc' to pass the svg as an url that will rendered
 * as a background image.
 * 2. Prop 'svgIconComponent' to render the <svg /> element in the 'DOM'.
 *
 * Prefer the 1st method use the 2nd one only if you have to dynamically
 * change some 'svg' attribute, e.g. fill, stroke, style etc...
 *
 * @example
 * ```javascript
 * // This svg is imported as a component, see:
 * // https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
 * import Icon from '...';
 * // <PlaceholderIcon /> -> renders '<svg />'
 * import { ReactComponent as PlaceHolderIcon } from 'src/images/icons/placeholder-icon.svg';
 *
 * // Renders '<div className="i-set-the-size-of-the-svg"><svg /></div>'.
 * // You can override the styles of the 'div' using 'style(Icon)`[other styles]`'.
 * const WrappedComp = <Icon svgIconComponent={PlaceHolderIcon} iconSize="1.6rem" />
 * ```
 */
const Icon: React.FC<IconProps> = ({
  fill = 'currentcolor',
  svgIconComponent: OptionalSvgIconComponent,
  iconSize,
  iconHeight,
  iconSrc,
  className,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  stroke,
  inlineIconSrc,
  viewBox,
  color,
  display,
  'aria-hidden': ariaHidden,
  onClick,
  cursor,
  style,
}) => (
  <IconRoot
    data-component="icon"
    iconSize={iconSize}
    className={className}
    iconSrc={iconSrc}
    iconHeight={iconHeight}
    margin={margin}
    marginTop={marginTop}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    inlineIconSrc={inlineIconSrc}
    aria-hidden={ariaHidden}
    onClick={onClick}
    color={color}
    display={display}
    cursor={cursor}
    style={style}
  >
    {OptionalSvgIconComponent && (
      <OptionalSvgIconComponent
        fill={fill}
        stroke={stroke}
        {...(viewBox && { viewBox })}
      />
    )}
  </IconRoot>
);

export default Icon;
