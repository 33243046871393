var ENV = '';

switch (true) {
  case window.location.host.includes('.dev.'):
  case window.location.host.includes('localhost'):
    ENV = 'dev';
    break;
  case window.location.host.includes('.test.'):
    ENV = 'test';
    break;
  default:
    ENV = 'production';
    break;
}

const prod = {
  apiUrl: `https://paylater-api.opyn.eu/api/v1`,
  language: `https://static.opyn.eu/opl/translations/prod/`,
  stripePromise:
    'pk_live_51LuIKPBHlcEWpgCkVmvKPuB1tXSEiwZYWR51mOAmnVcoYHY45xYdzLx2ZEUNDIPQd8fuZSBdMGxCEqYAWztVD4As00dOU2IXuY',
};

const dev = {
  apiUrl: `https://paylater-api.${ENV}.opyn.eu/api/v1`,
  language: `https://static.opyn.eu/opl/translations/${ENV}/`,
  stripePromise:
    'pk_test_51LuIKPBHlcEWpgCkCuB5TUL95AgdZlHznw3qzVYwiCO545sdtCm9M2hvzJG6vHV0crdkXqJsteOeVSIrsdFHUSKg00vF7t5utV',
};

const API_URL = ENV === 'production' ? prod.apiUrl : dev.apiUrl;
const LANGUAGE_URL = ENV === 'production' ? prod.language : dev.language;
const STRIPE_PROMISE =
  ENV === 'production' ? prod.stripePromise : dev.stripePromise;
const URL_FREECURRENCY = 'https://api.freecurrencyapi.com/v1/';
const KEY_FREECURRENCY = 'fca_live_7FcBSiD4mVmEBkwzMofUyL00LgEcGvYDoRjLbGyY';

export {
  API_URL,
  LANGUAGE_URL,
  STRIPE_PROMISE,
  URL_FREECURRENCY,
  KEY_FREECURRENCY,
};
