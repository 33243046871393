import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import Translate from 'src/components/Translate/Translate.component';
import React from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useLanguage } from 'src/services/i18n/i18n.context';
import { H5 } from 'src/style-utils/typographic';
import CheckoutForm from './partials/CheckoutForm.component';
import { STRIPE_PROMISE } from 'src/settings';
import { useCurrentTransactionStore, useProfileStore } from 'src/store/store';
import { PaymentFlowContextType } from '../../PaymentFlowPage.types';
import GoBackButton from 'src/components/GoBackButton/GoBackButton.component';

const StripePage: React.FC = () => {
  const location = useLocation();
  const clientSecretStripe = location.state?.clientSecretStripe;
  const stripePromise = loadStripe(`${STRIPE_PROMISE}`);
  const { language } = useLanguage();

  const { SelectedMethod, ChangedMethod } =
    useOutletContext<PaymentFlowContextType>();
  const firstMethod = ChangedMethod ?? SelectedMethod;
  const isBackup = firstMethod?.Type === 'sepa_debit';
  const type = useCurrentTransactionStore((state) => state.type);
  const canPayWithSDD = useProfileStore(
    (state) => state.currentOrder.CanPayWithSDD,
  );
  const options: StripeElementsOptions = {
    clientSecret: clientSecretStripe,
    locale: language,
  };

  return (
    <>
      <div className="w-full p-6 lg:px-12 lg:py-9">
        <GoBackButton />
        <H5 marginBottom="2.4rem">
          {!isBackup && type === 'pay' && canPayWithSDD ? (
            <Translate id="lbl.addMainPayment" />
          ) : isBackup && type === 'pay' && canPayWithSDD ? (
            <Translate id="lbl.addBackupMethod" />
          ) : type === 'pay' && !canPayWithSDD ? (
            <Translate id="text.addData" />
          ) : type === 'change' ? (
            <Translate id="lbl.addNewMethod" />
          ) : (
            <Translate id="lbl.addNewMethod" />
          )}
        </H5>
        <Elements
          stripe={stripePromise}
          options={options}
          data-cookieconsent="ignore"
        >
          <CheckoutForm />
        </Elements>
      </div>
    </>
  );
};

export default StripePage;
