/* Identifies the jwt token when it is serialized. */
export const authTokenKey = 'auth-token';

export const typeBuyerGuidKey = 'x-buyer-guid';
export const typeMerchantGuidKey = 'x-merchant-guid';

export const cancelCallbackUrlKey = 'cancelcallbackurl';

export const endCallbackUrlKey = 'endcallbackurl';

// Taken from 'https://github.com/facebook/create-react-app/blob/master/packages/cra-template/template/src/serviceWorker.js#L13'
export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);
