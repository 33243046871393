import Tabs from 'src/components/Tabs/Tabs.component';
import { ObjectTab } from 'src/components/Tabs/Tabs.types';
import { InstallmentsTabsProps } from './TabsInstallments.types';
import { InstallmentsCreated } from './tabCreated/InstallmentsCreated.component';
import { InstallmentsPaid } from './tabPaid/InstallmentsPaid.component';
import { InstallmentsOverdue } from './tabOverdue/InstallmentsOverdue.component';
import { useLocation } from 'react-router-dom';
import { InstallmentsProcessing } from './tabProcessing/InstallmentsProcessing.component';
import { useProfileStore } from 'src/store/store';

export const TabsInstallments: React.FC<InstallmentsTabsProps> = ({
  installments,
  isLoading,
}) => {
  const canPayWithSDD = useProfileStore((state) => state.CanPayWithSDD);
  const location = useLocation();
  const selected = location?.state?.selected;

  const TabsInstallments: ObjectTab[] = [
    {
      tabTitle: 'installments.expired',
      tabCount: installments?.ResultSet.InstallmentsOverdueCount,
      content: (
        <InstallmentsOverdue
          installments={installments}
          isLoading={isLoading}
        />
      ),
    },
    {
      tabTitle: 'lbl.expiring',
      tabCount: installments?.ResultSet.InstallmentsCreatedCount,
      content: (
        <InstallmentsCreated
          installments={installments}
          isLoading={isLoading}
        />
      ),
    },
  ];

  if (canPayWithSDD) {
    TabsInstallments.push({
      tabTitle: 'lbl.processing',
      tabCount: installments?.ResultSet.InstallmentsProcessingCount,
      content: (
        <InstallmentsProcessing
          installments={installments}
          isLoading={isLoading}
        />
      ),
    });
  }

  TabsInstallments.push({
    tabTitle: 'text.paid',
    tabCount: installments?.ResultSet.InstallmentsPaidCount,
    content: (
      <InstallmentsPaid installments={installments} isLoading={isLoading} />
    ),
  });

  return (
    <div className="py-10">
      <Tabs
        tabs={TabsInstallments}
        select={selected ?? 0}
        variant="Secondary"
        margin="0 auto 3.2rem"
      />
    </div>
  );
};
