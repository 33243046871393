import React from 'react';
import { ReactComponent as LoaderIcon } from 'src/images/icons/loading.svg';
import { LoaderProps } from './Loader.types';

import DisableWindowScroll from 'src/components/DisableWindowScroll/DisableWindowScroll.component';
import {
  CreditWorthinessBodyContainer,
  CreditWorthinessBodySubTitle,
  CreditWorthinessBodyTitle,
  LoaderContainer,
  OverlayContainer,
  OverlayIcon,
} from './style/Loader.style';
import Translate from '../Translate/Translate.component';

export const CreditWorthinessBody: React.FC = () => {
  return (
    <>
      <CreditWorthinessBodyContainer>
        <CreditWorthinessBodyTitle>
          <Translate id="text.getUWLoaderTitle" />
        </CreditWorthinessBodyTitle>
        <CreditWorthinessBodySubTitle>
          <Translate id="text.getUWLoaderSubTitle" />
        </CreditWorthinessBodySubTitle>
      </CreditWorthinessBodyContainer>
    </>
  );
};

const Loader: React.FC<LoaderProps> = ({
  viewMode,
  overlayViewMode,
  active,
  children,
  className,
  animationDelayMs,
  backgroundColor,
  backdropFilter,
}) => {
  return (
    <LoaderContainer
      data-component="loader"
      viewMode={viewMode}
      className={className}
      active={active}
    >
      {active && (
        <OverlayContainer
          active={active}
          overlayViewMode={overlayViewMode}
          animationDelayMs={animationDelayMs}
          backgroundColor={backgroundColor}
          backdropFilter={backdropFilter}
        >
          {overlayViewMode === 'fullscreen' && <DisableWindowScroll />}
          <OverlayIcon>
            <LoaderIcon width="6rem" height="6rem" />
            {children}
          </OverlayIcon>
        </OverlayContainer>
      )}
    </LoaderContainer>
  );
};

export default Loader;
