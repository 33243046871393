import { ButtonContainer } from 'src/components/Button/style/Button.style';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import SubmitButton from 'src/components/SubmitButton/SubmitButton.component';
import Toast from 'src/components/Toast/Toast.component';
import Translate from 'src/components/Translate/Translate.component';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { invoiceDateField } from 'src/services/orderLink/orderLink.config';
import { usePayByLinkStore, useProfileStore } from 'src/store/store';
import { H4, P } from 'src/style-utils/typographic';
import { FlowContainer } from 'src/style/Container.style';
import { IDs } from 'src/utils/config/ids';
import { formConfigInvoiceDatePayByLink } from './StepInvoiceDate.helpers';
import DatePickerField from 'src/components/DatePickerField/DatePickerField.component';
import React from 'react';

function StepInvoiceDate() {
  const { productCode, categoryCode } = useParams();
  const orderInvoiceRange = useProfileStore((state) => state.OrderInvoiceRange);

  const minDate = moment(new Date()).subtract(
    orderInvoiceRange.DaysBefore,
    'days',
  );
  const maxDate = moment(new Date()).add(orderInvoiceRange.DaysAfter, 'days');

  const { initialValues, resolver } = formConfigInvoiceDatePayByLink(
    '',
    orderInvoiceRange.DaysBefore,
    orderInvoiceRange.DaysAfter,
  );
  const navigate = useNavigate();
  const setValue = usePayByLinkStore((state) => state.setValue);

  const methods = useForm({
    defaultValues: initialValues,
    resolver,
    mode: 'onChange',
  });

  const onSubmit = async (data: any) => {
    setValue('invoiceDate', data[invoiceDateField]);
    setValue('flagCredit', true);
    navigate(
      `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSendLink}`,
    );
  };

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={12}
        stepMax={13}
        title="lbl.invoiceDate"
      />
      <FlowContainer>
        <H4 light>
          <Translate id="text.payByLinkInvoiceDate" />
        </H4>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="content">
            <DatePickerField
              className={'w-100'}
              name={invoiceDateField}
              minDate={new Date(minDate.toString())}
              maxDate={new Date(maxDate.toString())}
              popperModifiers={[
                {
                  name: 'arrow',
                  options: { padding: 24 },
                },
              ]}
              isPresentMessage
            />

            <P margin="4rem 0" className="centerOnDesktop">
              <Translate
                id="text.invoiceDate"
                data={{
                  invoiceDate: minDate.format('DD MMMM yyyy'),
                }}
              />
            </P>
            {methods.formState.errors[invoiceDateField] && (
              <Toast
                variant="info"
                title="text.nextWithoutFlagCredit"
                subTitle="text.continueCreating"
                buttonAction={() => {
                  setValue('flagCredit', false);
                  navigate(
                    `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSendLink}`,
                  );
                }}
                buttonText="lbl.go"
              />
            )}
            <ButtonContainer>
              <SubmitButton minWidth="100%" id={IDs.btnProceed}>
                <Translate id="lbl.next" />
              </SubmitButton>
            </ButtonContainer>
          </form>
        </FormProvider>
      </FlowContainer>
    </>
  );
}

export default StepInvoiceDate;
