import { Button } from 'src/components/Button/Button.component';
import Loader from 'src/components/Loader/Loader.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as Back } from 'src/images/icons/arrowLeft.svg';
import PaymentMethodContainer from 'src/pages/ecommerce/partials/ecommerceCheckout/funnelPaymentMethod/PaymentMethodContainer.component';
import { PaymentMethodResult } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { BuyerRegistrationStatus } from 'src/services/ecommerce/ecommerce.types';
import {
  createSetupAttemptMethodApi,
  getPaymentMethodsApi,
} from 'src/services/payments/payments.request';
import { useSession } from 'src/services/session/session.context';
import {
  useAccountStore,
  useCurrentTransactionStore,
  useProfileStore,
} from 'src/store/store';
import { P } from 'src/style-utils/typographic';
import FakeCheckoutForm from '../FakeCheckoutForm/FakeCheckoutForm.component';
import PaymentForm from '../PaymentForm/PaymentForm.component';
import { EcommerceContext } from 'src/pages/ecommerce/Ecommerce.component';

type PaymentCardProps = {
  dataCreateOrder: any;
  getPluginData: any;
  isSuccessCreateOrder: boolean;
  isLoading: boolean;
  isLoadingUW: boolean;
};

const PaymentCard = ({
  dataCreateOrder,
  isSuccessCreateOrder,
  getPluginData,
  isLoadingUW,
}: PaymentCardProps) => {
  const isLoadingConfirmOrder =
    useOutletContext<EcommerceContext>().isLoadingConfirmOrder;
  const [addingNewMethod, setAddingNewMethod] = useState(false);
  const buyerRoles = useAccountStore((state) => state.BuyerRoles);
  const guidParam =
    getPluginData?.BuyerRegistrationStatus !==
    BuyerRegistrationStatus.registered
      ? buyerRoles && buyerRoles[0].Guid
      : undefined;
  const { auth } = useSession();
  const navigate = useNavigate();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const firstInstallmentDueDate = currentOrder?.FirstInstallmentDueDate;
  useEffect(() => {
    if (isSuccessCreateOrder && dataCreateOrder) {
      const { OrderGuid } = dataCreateOrder;
      mutateGetPayment(OrderGuid);
    }
  }, [isSuccessCreateOrder]);

  const { orderGuid, transactionsGuid, paymentMethodId } =
    useCurrentTransactionStore((state) => state);

  const {
    mutate: mutateGetPayment,
    data: paymMethods,
    isLoading: isLoadingGetPayment,
  } = useMutation(getPaymentMethodsApi, {
    onSuccess: (res) => {
      if (res.data.ResultSet.length === 0) {
        mutatePaymentSetup({ orderGuid, isBackup: false });
      }
    },
  });
  const {
    mutate: mutatePaymentSetup,
    data: dataPaymentSetup,
    isLoading: isLoadingStripeSetup,
  } = useMutation(createSetupAttemptMethodApi, {
    onSuccess: () => {
      setAddingNewMethod(true);
    },
    onError: () => navigate(RoutePath.oopsPlugin),
  });

  // const dataPayment =
  //   payType === 'pay' ? dataPaymentIntent?.data : dataPaymentSetup?.data;
  const dataPayment = dataPaymentSetup?.data;

  const PaymentMethodsArray: PaymentMethodResult[] =
    paymMethods?.data.ResultSet ?? [];

  const pContext: pContextType = {
    orderGuid,
    transactionsGuid,
    paymentDate: currentOrder?.FirstInstallmentDueDate,
    amount: dataCreateOrder?.FirstInstallmentAmount,
    selectedMethodId:
      paymentMethodId > 0
        ? paymentMethodId
        : PaymentMethodsArray.length > 0
          ? PaymentMethodsArray[0].Id
          : 0,
    guidParam,
    paymentMethods: PaymentMethodsArray,
    goToStripeFormHandler: (
      values = {
        orderGuid,
        transactionsGuid,
      },
    ) => {
      // payType === 'pay'
      //   ? mutatePaymentIntent(values)
      //   :
      mutatePaymentSetup({ orderGuid, isBackup: false });
    },
  };

  if (
    !auth.authToken ||
    (!dataPayment?.ResultSet && PaymentMethodsArray.length < 1)
  ) {
    return (
      <FakeCheckoutForm
        isLoading={
          // isLoadingStripeIntent ||
          isLoadingStripeSetup || isLoadingGetPayment || isLoadingUW
        }
      />
    );
  }

  if (isLoadingGetPayment || isLoadingStripeSetup || isLoadingConfirmOrder) {
    return (
      <div style={{ position: 'relative', minHeight: '30rem' }}>
        <Loader overlayViewMode="content" active viewMode="absolute">
          <P>
            <Translate id="text.loaderEcommerceCheckout" />
          </P>
          <P bold colorBlack>
            <Translate id="text.dontClosePage" />
          </P>
        </Loader>
      </div>
    );
  }

  return (
    <>
      {PaymentMethodsArray.length > 0 && addingNewMethod && (
        <Button
          variant="LinkPrimary"
          padding="0"
          minWidth="fit-content"
          margin="0"
          onClick={() => setAddingNewMethod(false)}
          leftChild={<Back width="4rem" height="4rem" />}
        />
      )}
      {PaymentMethodsArray.length < 1 || addingNewMethod ? (
        <PaymentForm
          secretKey={dataPayment?.ResultSet}
          stateStripe={{
            amount: dataCreateOrder?.FirstInstallmentAmount,
            guidParam,
            paymentDate: firstInstallmentDueDate,
            orderGuid,
          }}
        />
      ) : (
        <PaymentMethodContainer pContext={pContext} />
      )}
    </>
  );
};

export default PaymentCard;
