import React, { useState } from 'react';
import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as ChangedMethodSvg } from 'src/images/icons/changed_method_icon.svg';
import { ReactComponent as CreditCard } from 'src/images/icons/credit-card.svg';
import { ReactComponent as RoundStepper } from 'src/images/icons/jump-stepper-round.svg';
import { ReactComponent as Phone } from 'src/images/icons/phone.svg';
import { ReactComponent as EuroSymbol } from 'src/images//icons/euroSymbol.svg';
import HowDoesItWorkCard from 'src/pages/PurchaseFlow/subcomponents/HowDoesItWorkCard';
import { PaymentFlowContextType } from 'src/pages/paymentFlow/PaymentFlowPage.types';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { useI18n } from 'src/services/i18n/i18n.hooks';
import { useProfileStore } from 'src/store/store';
import { H4, H5, P } from 'src/style-utils/typographic';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';
import { getPaymentIcon } from 'src/components/PaymentMethodElement/PaymentMethodElement.helpers';
import {
  PaySuccessCard,
  PSCardProps,
} from 'src/components/PaySuccessCard/PaySuccessCard.component';
import { ReactComponent as Calendar } from 'src/images/icons/calendarSwipe.svg';
import { ReactComponent as StepJumper } from 'src/images/icons/step-jumper.svg';
import { ReactComponent as IconBill } from 'src/images/icons/iconBill.svg';
import {
  BodyContainer,
  CopyContainer,
  IconContainer,
  OrderDetailCardContainer,
  OrderDetailTitleContainer,
} from '../../../registration/partials/SuccessPage/SuccessPage.style';
import { Flex } from 'src/style/flexbox.style';
import { AlertContainer } from 'src/components/ORION/Alert/Alert.style';
import { AlertComponent } from 'src/components/ORION/Alert/Alert.component';
import SepaPaymentInfoModalLoadable from '../paymentSuccessPage/partials/SepaPaymentInfoModal/SepaPaymentInfoModal.loadable';

function SetupSuccessPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { successData } = useOutletContext<PaymentFlowContextType>();
  const currentOrder = useProfileStore((state) => state.currentOrder);
  const location = useLocation();
  const guidParam = location?.state?.guidParam;
  const { NextAmount, NextDueDate, PaymentMethod } = successData ?? {};

  const {
    formatters: { formatCurrency },
  } = useI18n();

  const singleInstallment = currentOrder.NextInstallmentAmount === null;

  const icon = getPaymentIcon(PaymentMethod?.Details.Network);
  const { Last4, Country } = PaymentMethod?.Details ?? {};
  const isSepaDebit = PaymentMethod?.Type === 'sepa_debit';

  const formattedAmount = formatCurrency(NextAmount ?? 0);
  const formatteDueDate = refactorFormatDate(NextDueDate ?? '', 'DD MMMM YYYY');
  const userGuest =
    currentOrder?.BuyerRegistrationStatus &&
    currentOrder?.BuyerRegistrationStatus !== 'registered';
  const labels = {
    title: singleInstallment
      ? 'text.setupPaymentSingleInstallment'
      : 'text.setupPaymentInstallment',
    subtitle: singleInstallment
      ? 'text.setupSuccessSingleInstallmentSubtitle'
      : 'text.setupSuccessSubtitle',
  };
  const cardValues: PSCardProps[] = [
    {
      icon: EuroSymbol,
      lbl: 'text.paymentMethod',
      value: (
        <>
          <Flex
            style={{
              alignItems: 'center',
            }}
            gap="1.6rem"
          >
            <img className="h-10 w-10 p-1" src={icon} alt="card logo" />
            <H4 textAlign="right">
              {PaymentMethod?.Type === 'sepa_debit' && Country + ' '}
              &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;
              {Last4}
            </H4>
          </Flex>
        </>
      ),
      variant: 'column',
    },
    {
      icon: Calendar,
      lbl: 'text.nextRata',
      value: formatCurrency(currentOrder.FirstInstallmentAmount),
      note: NextDueDate
        ? refactorFormatDate(NextDueDate, 'ddd. DD MMMM')
        : undefined,
    },
    {
      icon: StepJumper,
      lbl: 'text.residualRataCount',
      value: currentOrder.InstallmentsNumber,
    },
    {
      icon: IconBill,
      lbl: 'lbl.remainingAmount',
      value: formatCurrency(currentOrder.FirstInstallmentAmount),
    },
  ];
  const buttonLabel = (() => {
    if (guidParam) return 'lbl.completeProfile';
    if (userGuest) return 'lbl.completeSignUp';
    return 'lbl.enterReservedArea';
  })();
  return (
    <>
      {isSepaDebit && (
        <>
          <AlertContainer>
            <AlertComponent
              variant="default"
              titleLbl="lbl.defAlertTYpage"
              hyperlinkLbl="lbl.whatsthemeaning2"
              onClick={() => setModalOpen(true)}
            />
          </AlertContainer>
          {modalOpen && (
            <SepaPaymentInfoModalLoadable
              modalTitle="lbl.sepaModalTItle"
              modalBody="lbl.sepaModalBody"
              i18n
              handleClose={() => setModalOpen(false)}
            />
          )}
        </>
      )}

      <BodyContainer>
        <IconContainer>
          <ChangedMethodSvg className="mx-auto w-66 lg:w-96" />
        </IconContainer>
        <CopyContainer>
          <H4>
            <Translate
              id={labels.title}
              data={{ amount: formattedAmount, dueDate: formatteDueDate }}
            />
          </H4>
        </CopyContainer>
        {userGuest && (
          <>
            <H5 textAlign="left" light margin="4.8rem 0 1.6rem">
              <Translate id="lbl.whyRegister" />
            </H5>
            <div className="mb-14 grid gap-4 text-left lg:grid-cols-2">
              <HowDoesItWorkCard title="text.keepTrack" icon={RoundStepper} />
              <HowDoesItWorkCard
                title="text.associatePayment"
                icon={CreditCard}
              />
              <HowDoesItWorkCard title="text.accessAnywhere" icon={Phone} />
            </div>
          </>
        )}
      </BodyContainer>

      <ButtonContainer marginTop="40px" marginBottom="40px">
        <Button
          onClick={() => {
            if (!userGuest)
              return navigate(`${RoutePath.privateArea}?typeAccount=BUYER`);

            return navigate(
              `${RoutePath.registrationGuest}${RoutePath.emailStep}?guid=${currentOrder?.BuyerGuid}`,
            );
          }}
          variant="Primary"
          height="5.4rem"
          type="submit"
          minWidthMobile="100%"
        >
          <Translate id={buttonLabel} />
        </Button>
      </ButtonContainer>

      <div className="bg-white py-10">
        <div className="container">
          <OrderDetailTitleContainer>
            <H4 light>
              <Translate id="lbl.TyPageDetailTitle" />
            </H4>
            <P>
              <Translate id="text.paymentSuccessSubtitle" />
            </P>
          </OrderDetailTitleContainer>
          <OrderDetailCardContainer>
            {cardValues.map((item, i) => (
              <PaySuccessCard key={i} {...item} />
            ))}
          </OrderDetailCardContainer>
        </div>
      </div>
      {window.innerWidth < 1024 && <div style={{ height: '15vh' }}></div>}
    </>
  );
}

export default SetupSuccessPage;
