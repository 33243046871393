import moment from 'moment';
import { SyncFieldValidator } from './types';
import { refactorFormatDate } from 'src/utils/functions/refactorFormatDate';

export const adultMessage = 'error.notAdult';
export const invalidDate = 'error.invalidDate';
export const incorrectDate = 'error.incorrectDate';
export const incorrectInvoiceDate = 'error.incorrectInvoiceDate';
export const incorrectStartDate = 'error.incorrectStartDate';

export const birthDate: SyncFieldValidator = (
  value: string,
): string | undefined => {
  const inputDate = getMomentDate(value);
  if (moment(new Date()).diff(moment(inputDate), 'years') < 18)
    return adultMessage;
};
export const validDate: SyncFieldValidator = (
  value: string,
): string | undefined => {
  const inputDate = getMomentDate(value);
  if (!inputDate.isValid()) return invalidDate;
};

const getMomentDate = (date: string) => {
  return moment({
    day: parseInt(date.substring(0, 2), 10),
    month: parseInt(date.substring(5, 3), 10) - 1,
    year: parseInt(date.substring(6), 10),
  });
};

export const validateEndDate = function validateEndDate(
  fromDate: string | undefined,
): SyncFieldValidator {
  return function validateLength(value: unknown) {
    if (
      typeof fromDate !== 'string' ||
      typeof value !== 'string' ||
      moment(fromDate) > moment(value)
    ) {
      return invalidDate;
    }
    return undefined;
  };
};

export const validateFlexDate = function validateEndDate(
  previousDate: string | undefined | null,
  remodulationFlow: boolean,
): SyncFieldValidator {
  const minDate = remodulationFlow
    ? moment().add(3, 'd').startOf('day')
    : moment().startOf('day');

  return function validateLength(date: unknown) {
    const value = refactorFormatDate(`${date}`, 'YYYY-MM-DDTHH:mm:ss.SSS');
    if (
      typeof value !== 'string' ||
      moment(value).isBefore(previousDate ?? minDate) ||
      moment(value).isSame(previousDate, 'day') ||
      moment(value).isAfter(moment().add('years', 3))
    ) {
      return incorrectDate;
    }
    return undefined;
  };
};

export const validateInvoiceDate = function validateInvoiceDate(
  date?: string,
  daysBefore: number = 15,
  daysAfter: number = 0,
): SyncFieldValidator {
  return function validateLength(value: unknown) {
    if (
      date &&
      value instanceof Date &&
      !moment(value).isBetween(
        moment(date).startOf('day').subtract('days', daysBefore),
        moment(date).endOf('day').add('days', daysAfter),
        null,
        '[]',
      )
    ) {
      return incorrectInvoiceDate;
    }
    if (
      !date &&
      value instanceof Date &&
      !moment(value).isBetween(
        moment().startOf('day').subtract('days', daysBefore),
        moment().endOf('day').add('days', daysAfter),
        null,
        '[]',
      )
    ) {
      return incorrectInvoiceDate;
    }
    return undefined;
  };
};

export const validateStartDate = function validateInvoiceDate(
  date?: string,
): SyncFieldValidator {
  return function validateLength(value: unknown) {
    if (
      typeof value !== 'string' ||
      moment(value).isBefore(moment(date).add('days', 2))
    ) {
      return incorrectDate;
    }
    return undefined;
  };
};
