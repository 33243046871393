import { Button } from 'src/components/Button/Button.component';
import { ButtonContainer } from 'src/components/Button/style/Button.style';
import Icon from 'src/components/Icon/Icon.component';
import PayByLinkHeader from 'src/components/PayByLinkHeader/PayByLinkHeader.component';
import { TextDefaultPage } from 'src/components/TextDefaultPage/TextDefaultPage.component';
import ToastCard from 'src/components/ToastCard/ToastCard.component';
import Translate from 'src/components/Translate/Translate.component';
import { ReactComponent as BankIcon } from 'src/images/icons/paymentMethods/bank.svg';
import { ReactComponent as CustomerValidationKO } from 'src/images/icons/validationCustomerWarning.svg';
import { ReactComponent as CustomerValidationOK } from 'src/images/pageStatus/customer-validation-ok.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RoutePath } from 'src/routers/routers.config';
import { usePayByLinkStore } from 'src/store/store';
import { FlowContainer, ResponsiveContainer } from 'src/style/Container.style';
import { Flex } from 'src/style/flexbox.style';
import { ResultMapping, ResultUW } from './StepCustomerValidation.types.d';

function StepCustomerValidation() {
  const { productCode, categoryCode } = useParams();
  const location = useLocation();
  const Result = location?.state?.Result;
  const canGiveCredit = location?.state?.canGiveCredit;
  const canPayWithSDD = location?.state?.canPayWithSDD;
  const resultMappingKey = Result !== ResultUW.ok ? 2 : canGiveCredit ? 0 : 1;
  const resultLabel = ResultMapping[resultMappingKey];
  const setValue = usePayByLinkStore((state) => state.setValue);
  const navigate = useNavigate();
  const canGiveCreditAtCreation = usePayByLinkStore(
    (state) => state.customInstallments?.CanGiveCreditAtCreation,
  );

  const onSubmit = async () => {
    setValue('flagCredit', false);
    if (canGiveCredit && canGiveCreditAtCreation) {
      navigate(
        `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepCollectionMethod}`,
      );
      return;
    }
    navigate(
      `${RoutePath.createLink}${categoryCode}/${productCode}/${RoutePath.stepSendLink}`,
    );
  };

  return (
    <>
      <PayByLinkHeader
        stepBack
        step={8}
        stepMax={9}
        title="lbl.customerValidation"
      />
      <FlowContainer>
        <Icon
          iconHeight="19.3rem"
          iconSize="26.4rem"
          svgIconComponent={
            Result === ResultUW.ok ? CustomerValidationOK : CustomerValidationKO
          }
          marginBottom="3.1rem"
          margin="auto"
        />
        <TextDefaultPage title={'lbl.UWCompleted'} margin="0" />
      </FlowContainer>
      <ResponsiveContainer padding="0 0 10rem">
        <Flex gap="1.6rem" margin="0 auto" responsive maxWidth="fit-content">
          <ToastCard
            title="lbl.UWTitle"
            subTitle={resultLabel.resultCard.subtitle}
            customIcon={resultLabel.resultCard.icon}
            variant={resultLabel.resultCard.variant}
          />
          {canGiveCredit && canGiveCreditAtCreation && (
            <ToastCard
              title="lbl.labelADV"
              subTitle={resultLabel.advPaymentCard.subtitle}
              customIcon={resultLabel.advPaymentCard.icon}
              variant={resultLabel.advPaymentCard.variant}
            />
          )}
          {canPayWithSDD && (
            <ToastCard
              title="lbl.UWOkSDD"
              subTitle="text.UWOkSDD"
              customIcon={BankIcon}
              variant="success"
            />
          )}
        </Flex>
        <ButtonContainer>
          <Button variant="Primary" minWidth="100%" onClick={() => onSubmit()}>
            <Translate id="lbl.next" />
          </Button>
        </ButtonContainer>
      </ResponsiveContainer>
    </>
  );
}

export default StepCustomerValidation;
